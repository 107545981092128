import React, {useState} from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import style from "./Cart.module.css"
import { Ring } from '@uiball/loaders'
import swal from "sweetalert";
import "./FinalizarCompra.css"

export default function FinalizarCompra({price,ptax,pvalue,priceTax,handlePurchase,priceTaxValue,impCarne}) {

  const {baseStore, minimumCartData, cart} = useSelector(s=>s)
  const [refresh, setRefresh] = useState(false)
  const [refreshCanBuy, setRefreshCanBuy] = useState(false)

  const despacho = useSelector((state) => state.frecuenciaDespacho);
  const minimo = minimumCartData?.minimoCompra
    const widd= priceTaxValue >= minimo ? 100 : (priceTaxValue / minimo) * 100

    const styles ={  width: `${widd}%`, height: "10px"  }
      
    const calculateIVA = (taxTotal,impCarne) => {
      return impCarne.length > 0 ? taxTotal - Number(impCarne == undefined ? "0" : impCarne.replace(".","")) : taxTotal;
    } 

    const formattedprice = (ptax,pvalue,impCarne) => {
      let ivanumber = ptax - pvalue;
      if(impCarne != undefined){
        ivanumber = calculateIVA(ptax - pvalue, impCarne);
      }
    
      let iva = ivanumber.toString()
      let dcimal = iva.slice(-3)
      let format = iva.split(dcimal)

      if(!cart?.entries?.length){
        return "$0"
      }
      return "$"+format[0]+"."+dcimal;
    }

    const canBuyChequera = (minimumCanBuy) => {
      if(minimumCanBuy != undefined){
        let canBuy = true;
        if(!minimumCanBuy){
          canBuy=false;
          if(!refreshCanBuy){
            swal({
              title: "Antes de continuar es necesario validar información de tu local con un ejecutivo.",
              text: "Favor cierra sesión en el menú lateral y escoge el menú Asesoría Venta Digital",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            })
            setRefreshCanBuy(true)
  
          }
        }
  
        return canBuy;
      }

    }
    
    const canBuyByFrecuencia = (despachoComponent) =>{
      let canBuyByFrecuencia = true;
      if(
      (despachoComponent?.cartHasFresco && despachoComponent?.fechasFresco?.length == 0) ||
      (despachoComponent?.cartHasProcesado && despachoComponent?.fechasProcesado?.length == 0) ||
      (!despachoComponent?.cartHasProcesado && despachoComponent?.fechasProcesado?.length == 0) ||
      (!despachoComponent?.cartHasFresco && despachoComponent?.fechasFresco?.length == 0)
      ){
        if(!refresh){
          swal({
            title: "Ups! nos falta tu frecuencia de reparto en sistema.",
            text: "Favor cierra sesión en el menú lateral y escoge el menú Asesoría Venta Digital",
            icon: "warning",
            buttons: true,
            dangerMode: true,
          })
          setRefresh(true)
        }

        canBuyByFrecuencia = false
      }
      return canBuyByFrecuencia;
    }

  return (
            <div className={style.cardData}>
              <span className={style.containText}>
                <p className={style.cardDataText}>Finalizar compra</p>
              </span>
              <div className={style.containerButtons}>
                <div className={style.containerCupon}>
                  <p>
                    Si tienes un cupón de descuento agrégalo <span>aquí</span>.
                  </p>
                  <div className={style.inputButton}>
                    <input
                      type={"text"}
                      placeholder="Ingrese el código"
                    ></input>
                    <button>Aplicar</button>
                  </div>
                </div>
                <div className={style.containerNetoPrice}>
                  <span>Neto</span>
                  <span>{price}</span>
                </div>
                <div className={style.containerNetoPrice}>
                  <span>IVA</span>
                  <span>{formattedprice(ptax,pvalue,cart.impuestoCarne)}</span>
                </div>
                {cart.totalDiscounts?.value > 0 ? (
                        <div className={style.containerNetoPrice}>
                        <span>Descuento</span>
                        <span>{cart.totalDiscounts.formattedValue}</span>
                      </div>
                ): null}

                {Number(cart.impuestoCarne) > 0 ? (
                        <div className={style.containerNetoPrice}>
                        <span>Impuesto a la Carne</span>
                        <span>${cart.impuestoCarne}</span>
                      </div>
                ): null}
                {/* <div className={style.containerNetoPriceF}>
                  <span>Impuesto adicional</span>
                  <span>$0</span>
                </div> */}

                <div className={style.containerTotalPrice}>
                  <span className={style.precioTotal}>Total Bruto*</span>
                  <span className={style.precio}>{priceTax}</span>
                </div>

                <div className={style.barraLoad}>
                <span className={style.precioTota}>Monto mínimo neto {minimo ? ("$" + minimo) : (
                  <Ring 
                  size={20}
                  lineWeight={5}
                  speed={2} 
                  color="black" 
                 />
                )}</span>
                <div className="progress">  
                <div className="progress-bar"  
                role="progressbar"  style={styles}  
                aria-valuenow={`${widd}`}  aria-valuemin="0" 
                 aria-valuemax="100"></div></div>
                </div>

                
               
                <span className={style.spanValuesRef}>*Valores de referencia.  Pueden variar debido al peso de los productos.</span>
                
                <Link to={`/checkout`} className={!(cart?.entries?.length && canBuyChequera(minimumCartData?.canBuy) && minimumCartData?.superaMinimo &&  canBuyByFrecuencia(despacho)) ? style.disabledLink : ""}>
              <button
                onClick={()=> handlePurchase()}
                className={style.buttonPurchase}
                disabled={ !(cart?.entries?.length && canBuyChequera(minimumCartData?.canBuy) && minimumCartData?.superaMinimo &&  canBuyByFrecuencia(despacho))}
              >
                Comprar ahora
              </button>
              </Link>
              </div>
            </div>
  )
}