import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getfilter, clearDetailCategories } from "../../Services/actions";
import style from "./Paginate.module.css";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

export default function Paginate() {
  const dispatch = useDispatch();

  const { paginate, currentQuery } = useSelector((state) => state);

  //Definimos 3 estados mas para limitar los numeros de las paginas
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(
    paginate.totalPages
  ); //cantidad maxima de numeros
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0); //cantidad minima de numeros

  const [limit,setLimit] = useState( maxPageNumberLimit >= 5 ? 5 : maxPageNumberLimit)

  const [minLimit,setMinLimit] = useState(0)

  const products = paginate.totalResults;
  const maxProdPorPag = paginate.pageSize;
  const pagActual = paginate.currentPage;
  const orderQuery = localStorage.getItem("order");
  const queryLocal = localStorage.getItem("query");
  let queries = {
    currentPage: 0,
    pageSize: 20,
    sort: paginate.sort,
    query: currentQuery.query.value,
  };

  const params = useParams().idCat;
  useEffect(() => {}, [params, orderQuery, queryLocal]);

  const paginateP = (e) => {
    queries.currentPage = e.target.value;
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(getfilter(queries));
  };

  const Prev = (e) => {
    queries.currentPage = pagActual - 1;
    if((queries.currentPage + 1) <= minLimit && minLimit !== 0){
      setMinLimit(minLimit - 5)
      setLimit( (limit % 5) === 0 ? limit - 5 : limit - (limit % 5) )
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(getfilter(queries));
  };

  const Next = (e) => {
    queries.currentPage = pagActual + 1;
    if((queries.currentPage + 1) > limit){
      setMinLimit(limit)
      setLimit(maxPageNumberLimit >= (limit + 5)? limit + 5 : maxPageNumberLimit)
    }
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(getfilter(queries));
  };

  const pages = [];

  for (let i = 1; i <= Math.ceil(products / maxProdPorPag); i++) {
    pages.push(i);
  }

  const renderPages = pages.slice(minLimit,limit)
  

  //funcion para renderizar los numeros
  const renderPageNumbers = renderPages?.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          value={number - 1}
          onClick={paginateP}
          className={style.li}
          style={{
            backgroundColor: pagActual === number - 1 ? "#e68e4b" : "",
            color: pagActual === number - 1 ? "white" : "",
          }}
        >
          {" "}
          {number}
        </li>
      );
    } else {
      return null;
    }
  });


  return (
    <div className={style.divPaginado}>
      <ul className={style.pageNumbers}>
        <li className={pagActual === 0 ? style.disabledLi : style.li}>
          <button
            onClick={() => {
              Prev();
            }}
            className={style.btn}
            disabled={pagActual === 0 ? true : false}
          >
            <FiChevronLeft />
          </button>
        </li>
        {renderPageNumbers}
        <li className={pagActual === pages[pages.length - 2] || pages.length === 1 ? style.disabledLi : style.li}>
          <button
            onClick={() => {
              Next();
            }}
            className={style.btn}
            disabled={
              pagActual === pages[pages.length - 2] || pages.length === 1
                ? true
                : false
            }
          >
            <FiChevronRight />
          </button>
        </li>
      </ul>
    </div>
  );
}
