import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import MediaQuery from 'react-responsive'
import { useParams } from 'react-router-dom';
import { clearDetailCategories, getfilter } from '../../Services/actions';
import { ccommerceProvider } from '../Context/CcommerceProvider';
import style from "./FilterBar.module.css";




export const FilterBar = () => {
  const params = useParams().idCat;
  const dispatch = useDispatch()
  const { facets } = useSelector(state => state)
  const queryLocal = localStorage.getItem("query")
  const orderQuery = localStorage.getItem("order")

  let queries = {
    currentPage: 0,
    pageSize: 20,
    sort: "relevance",
    query: ":DEFAULT"
  }
  const [flagMasCategorias, setFlagMasCategorias] = useState(false)
  const [modal, setModal] = useState(false)
  const {  selectedOrder, setSelectedOrder } = useContext(ccommerceProvider);

  const [flagQuery, setflagQuery] = useState(false)
  const [fAplicado, setFAplicado] = useState({
    status: false,
    filtro: []
  })
  useEffect(() => {
    setFlagMasCategorias(false)
    /* if(params.length===3){
      console.log("params: ", params.slice(0,1)+0);
      localStorage.setItem("query",`?q=%3Arelevance%3AallCategories%3A${params.slice(0,1)+0}%3Acategory%3A${params}`)
    } */

    return () => {

     /*  dispatch(clearDetailCategories()); */
    };
  }, [params, fAplicado, queryLocal, flagQuery])
  let nameEstado = facets.estado.name;
  let valuesEstado = facets.estado.values;
  let nameCategory = facets.categoria.name;
  let topValuesCategories = facets.categoria.topValues?.filter(e => e.name !== "General");
  let valuesCategories = facets.categoria.values?.filter(e => e.name !== "General");
  let selectedTV = facets.categoria.topValues?.filter(e => e.selected === true)
  let selectedV = facets.categoria.values?.filter(e => e.selected === true).concat(selectedTV)
  let result = []
  for (let i = 0; i < selectedV?.length; i++) {
    if (selectedV[i]?.name === selectedV[i + 1]?.name) {

    } else {
      result.push(selectedV[i])
    }
  }
  let selectedAll = facets.estado.values?.filter(e => e.selected === true)
  const masCategorias = (e) => {
    e.preventDefault()
    flagMasCategorias ? setFlagMasCategorias(false) : setFlagMasCategorias(true)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    dispatch(clearDetailCategories());
    let val = document.querySelector('input[name="color"]:checked')
    if (val !== null) {
      const newQuery = val.value.split(",");

      localStorage.setItem("query", newQuery[2].slice(10))
      setFAplicado({
        status: true,
        filtro: [...fAplicado.filtro, newQuery[1]]
      })
      queries.query = `${newQuery[0]}`

      setSelectedOrder(!selectedOrder) 
      setModal(!modal)
      dispatch(getfilter(queries))

    }
  }

  const deleteFilter = (e, url) => {
    e.preventDefault()
    dispatch(clearDetailCategories());
    localStorage.setItem("query", url.slice(10))
    setflagQuery(!flagQuery)
    setSelectedOrder(!selectedOrder) 
  }

  const handleModal = () => {
    setModal(!modal)
  }
  
  if(valuesCategories?.length === 0){
return(
  <div className={style.spinnerContainer}>
<div class="spinner-border text-warning" role="status">
  <span class="sr-only">Loading...</span>
</div>
</div>
)
  }else{
  return (
    <div>
      
      {selectedAll?.length || result?.length ? <div className={selectedAll?.length>1 || result?.length>1 ?style.filtrosAplicados2:style.filtrosAplicados}>
        <div className={style.sOfertastxtFiltros}>Filtros aplicados</div>

        {selectedAll?.map((e, i) => {
          return (
            <div key={i}><span className={style.nameFiltros}>{e?.name}</span> <button className={style.deleteFilter}
              onClick={(f) => deleteFilter(f, e.query.url)}>x</button></div>
          )
        })}
        {result?.map((e, i) => {
          return (
            <div key={i}><span className={style.nameFiltros}>{e?.name}</span> <button className={style.deleteFilter}
              onClick={(f) => deleteFilter(f, e.query.url)}>x</button></div>
          )
        })}

      </div> :<div className={style.filtrosNoAplicados}></div>}
      <form className={style.formnotMedia} onSubmit={handleSubmit}>
        <div className={style.sOfertastxt}>{nameCategory}</div>

        {topValuesCategories?.length ? <>
          {!flagMasCategorias && topValuesCategories.map((f, i) => {

            return (
              <div className={style.checkboxOfertas} key={i}>
                <div>
                  <input className={style.inputt} key={i} name="color" type="radio" id="cbox1"
                    value={[f.query.query.value, f.name, f.query.url]}

                  />
                  <label htmlFor="cbox1">{f.name} ({f.count})</label>
                </div>
              </div>
            )
          })}
          {flagMasCategorias && valuesCategories.map((f, i) => {

            return (
              <div className={style.checkboxOfertas2} key={i}>
                <input className={style.inputt} key={i} name="color" type="radio" id="cbox2"
                  value={[f.query.query.value, f.name, f.query.url]}

                />
                <label htmlFor="cbox1">{f.name} ({f.count})</label>
              </div>
            )
          })}
          {valuesCategories?.length !== 0 && !flagMasCategorias ? <div
            onClick={(e) => masCategorias(e)} className={style.sOfertastxt2}>mas categorías ...</div>
            : <div
              onClick={(e) => masCategorias(e)} className={style.sOfertastxt2}>menos categorías ...</div>}

        </>
          : valuesCategories?.length ? valuesCategories.map((f, i) => {

            return (
              <div className={style.checkboxOfertas} key={i}>
                <div>
                  <input className={style.inputt} key={i} name="color" type="radio" id="cbox1"
                    value={[f.query.query.value, f.name, f.query.url]}

                  />
                  <label htmlFor="cbox2">{f.name} ({f.count})</label>
                </div>
              </div>
            )
          }) : <>
            <div className={style.sOfertastxt}>No exiten mas categorías para este producto</div>

          </>
        }


        <div className={valuesEstado?.length ?style.sOfertastxt:style.displayNone}>{nameEstado?.length !== 0 ? nameEstado : null}</div>
        {valuesEstado?.length ? valuesEstado.map((f, i) => {

          return (
            <div className={style.checkboxOfertas} key={i}>
              <div>
                <input className={style.inputt} key={i} name="color" type="radio" id="cbox1"
                  value={[f.query.query.value, f.name, f.query.url]}

                />
                <label htmlFor="cbox2">{f.name} ({f.count})</label>
              </div>
            </div>
          )
        })
          : null
        }
<div className={style.buttonFiltrarContainer}>
        <button className={style.buttonFiltrar} type="submit" >Filtrar</button>
        </div>
      </form>

      <MediaQuery maxWidth={768}>
        <div className={style.filterC}>
          <Button className={style.butonModal} onClick={() => handleModal()}><i className="fa-solid fa-filter"></i> Filtrar por: </Button>
          <Modal className={style.modalFilter} show={modal} onHide={() => handleModal()}>
            <Modal.Header closeButton></Modal.Header>
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className={style.sOfertastxt}>{nameCategory}</div>

                {topValuesCategories?.length ? <>
                  {!flagMasCategorias && topValuesCategories.map((f, i) => {

                    return (
                      <div className={style.checkboxOfertas} key={i}>
                        <div>
                          <input className={style.inputt} key={i} name="color" type="radio" id="cbox1"
                            value={[f.query.query.value, f.name, f.query.url]}

                          />
                          <label htmlFor="cbox1">{f.name} ({f.count})</label>
                        </div>
                      </div>
                    )
                  })}
                  {flagMasCategorias && valuesCategories.map((f, i) => {

                    return (
                      <div className={style.checkboxOfertas2} key={i}>
                        <input className={style.inputt} key={i} name="color" type="radio" id="cbox2"
                          value={[f.query.query.value, f.name, f.query.url]}

                        />
                        <label htmlFor="cbox1">{f.name} ({f.count})</label>
                      </div>
                    )
                  })}
                  {valuesCategories?.length !== 0 && !flagMasCategorias ? <div
                    onClick={(e) => masCategorias(e)} className={style.sOfertastxt2}>mas categorías ...</div>
                    : <div
                      onClick={(e) => masCategorias(e)} className={style.sOfertastxt2}>menos categorías ...</div>}

                </>
                  : valuesCategories?.length ? valuesCategories.map((f, i) => {

                    return (
                      <div className={style.checkboxOfertas} key={i}>
                        <div>
                          <input className={style.inputt} key={i} name="color" type="radio" id="cbox1"
                            value={[f.query.query.value, f.name, f.query.url]}

                          />
                          <label htmlFor="cbox2">{f.name} ({f.count})</label>
                        </div>
                      </div>
                    )
                  }) : <>
                    <div className={style.sOfertastxt}>No exiten mas categorías para este producto</div>

                  </>
                }


                <div className={style.sOfertastxt}>{nameEstado?.length !== 0 ? nameEstado : null}</div>
                {valuesEstado?.length ? valuesEstado.map((f, i) => {

                  return (
                    <div className={style.checkboxOfertas} key={i}>
                      <div>
                        <input className={style.inputt} key={i} name="color" type="radio" id="cbox1"
                          value={[f.query.query.value, f.name, f.query.url]}

                        />
                        <label htmlFor="cbox2">{f.name} ({f.count})</label>
                      </div>
                    </div>
                  )
                })
                  : null
                }

              </Modal.Body>
              <Modal.Footer> <button className={style.buttonFiltrar} type="submit" >Filtrar</button> </Modal.Footer>
            </form>
          </Modal>
        </div>
      </MediaQuery>
    </div>
  )}
}