import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getfilter } from "../../Services/actions";
import style from "./SearchBar.module.css";
const url = process.env.REACT_APP_API_ADMIN;

export default function SearchBar() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dropDown } = useSelector((state) => state);
  const [search, setSearch] = useState("");

  let queries = {
    currentPage: 0,
    pageSize: 20,
    sort: "relevance",
    query: ":DEFAULT",
    flagBard: true,
    setSuccess: false,
  };


  const results = search.length > 2
    ? dropDown?.products?.filter((dato) =>
        dato.name?.toLowerCase().includes(search.toLowerCase())
      )
    : [];

  const handlefilter = (e) => {
    setSearch(e.target.value);
    queries.query = e.target.value;
    dispatch(getfilter(queries));
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const handleClear = (e) => {
    setSearch("");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if(!dropDown?.currentQuery?.url)return
    if (search.length > 2) {
      localStorage.setItem(
        "query",
        dropDown?.currentQuery?.url.replace("/search?q=", "")
      );
    }else{
      return
    }
    history.push(dropDown.currentQuery?.url);
    setSearch("");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <div className={style.containerSearch}>
      <div className={style.containerBuscador}>
        <form className={style.form} onSubmit={onSubmit}>
          <input
            className={style.input}
            placeholder="¿Qué producto buscas hoy para tu negocio?"
            required=""
            type="text"
            name="searchBar"
            onChange={handlefilter}

            autoComplete="off"
          />
          <button className={style.reset} type="reset" onClick={handleClear}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              ></path>
            </svg>
          </button>
          <button type="submit">
            <svg
              width="17"
              height="16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              role="img"
              aria-labelledby="search"
            >
              <path
                d="M7.667 12.667A5.333 5.333 0 107.667 2a5.333 5.333 0 000 10.667zM14.334 14l-2.9-2.9"
                stroke="currentColor"
                strokeWidth="1.333"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </button>
        </form>
      </div>
      <div className={style.contresult}>
        <div className={style.result}>
          {search.length !== 0 &&
            results?.map((e, i) => (
              <div key={i} className={style.itemsdrop}>
                {e.name ? (
                  <div>
                    <Link
                      to={`/product/${e.code}`}
                      className={style.spanItem}
                      onClick={handleClear}
                    >
                      <img
                        alt="imgproducto"
                        src={url + "/" + e.images[1]?.url}
                        className={style.imgSearch}
                      />{" "}
                      <span className={style.spanSearch}>{e.name}</span>
                    </Link>
                  </div>
                ) : null}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}