import { SourceRounded } from "@mui/icons-material";
import axios from "axios";
import {
  deleteRequest,
  getRequest,
  postRequest,
  promiseAllRequest,
  putRequest,
} from "../controllers/requestServices";
const customerUID = localStorage.getItem("B2BCUSTOMER_UID");
const customerId = localStorage.getItem("B2BCUSTOMER_UID")?.slice(2); // sin el c_
const tokenCommerce = sessionStorage.getItem("TOKEN_CCOMMERCE");

export function getProducts(obj) {
  return async function (dispatch) {
    const allData = await axios.post("/products", obj);
    return dispatch({
      type: "GET_PRODUCTS",
      payload: allData.data,
      filters: obj,
    });
  };
}
export function getSearch(obj) {
  return async function (dispatch) {
    const allData = await axios.get(`/products?productName=${obj}`);

    return dispatch({ type: "GET_SEARCH", payload: allData.data });
  };
}

export function getCategories() {
  return async function (dispatch) {
    let allData = await getRequest("/category-tree");
     allData.data.categories = allData.data.categories.map(e=>{
      const subC = e.subcategories.filter(j=> j.title != null )
      e.subcategories = subC
      return e
    }
    )
    return dispatch({ type: "GET_CATEGORIES", payload: allData.data });
  };
}

export function getSubCategories(idCategory) {
  return async function (dispatch) {
    const allData = await axios.get(`/cat?idCategory=${idCategory}`);
    return dispatch({ type: "GET_SUBCATEGORIES", payload: allData.data });
  };
}

export function clearSubcat() {
  return {
    type: "CLEAR_SUBCATEGORIES",
    payload: [],
  };
}

export function setQuantity(obj) {
  return async function (dispatch) {
    await axios.put("/cart/setQuantity", obj);
    return dispatch({ type: "SET_QUANTITY" });
  };
}

// export function removeProductCart(obj) {
//     return async function (dispatch) {
//         const result = await axios.delete(`/cart/delete?email=${obj.email}&idProduct=${obj.idProduct}`)
//         return dispatch({ type: 'REMOVE_PRODUCT', payload: result.data.idProduct })

//     }
// }

export function clearCart(obj) {
  return async function (dispatch) {
    await axios.delete(`/cart/clear?email=${obj.email}`);
    return dispatch({ type: "CLEAR_CART" });
  };
}

export function setCart(arr) {
  return {
    type: "SET_CART",
    payload: arr,
  };
}

export function purchaseCart(obj) {
  return async function (dispatch) {
    await axios.put(`/cart/logicalErase`, obj);
    return dispatch({ type: "PURCHASE_CART" });
  };
}

// Nuevas peticiones

export function getCatalogos() {
  return async function (dispatch) {
    const source = `/catalogs/customerId/${customerUID}`;
    const result = await getRequest(source);

    return dispatch({ type: "GET_CATALOGO", payload: result.data });
  };
}

export function getProductsSku(data) {
  return async function (dispatch) {
    const sources = data.map((productId) => {
      return `/products/productId/${productId}`;
    });
    const result = await promiseAllRequest(sources);
    const dataMap = result?.map((product) => {
      if (product.data.name !== "DEFAULT") {
        return product.data;
      }
    });
    const dataFilter = dataMap.filter((e) => e);
    return dispatch({ type: "GET_BEST_PRODUCTS", payload: dataFilter });
  };
}


export function getProductsPdp(data) {
  return async function (dispatch) {
    const sources = data.map((productId) => {
      return `/price/b2bUnitUid/${customerId}/productId/${productId}?qty=1`;
    });
    const result = await promiseAllRequest(sources);

    const dataMap = result?.map((product) => {
      if (product.data.hasOwnProperty("ZRPAData")) {
        return product.data;
      }
    });
    const dataFilter = dataMap?.filter((e) => e);
    return dispatch({ type: "GET_PRODUCTS_PDP", payload: dataFilter });
  };
}

export function getProductDiscountVolume(idProduct) {
  return async function (dispatch) {
    const source = `/price/volume-discount/b2bUnitUid/${customerId}/productId/${idProduct}`;
    const result = await getRequest(source);
    return dispatch({ type: "PRODUCT_DETAIL_DISCOUNT", payload: result.data });
  };
}

export function getProductDetailIntegration(idProduct, quantityCart) {
  return async function (dispatch) {
    const source = `/price/b2bUnitUid/${customerId}/productId/${idProduct}?qty=${quantityCart}`;
    const result = await getRequest(source);
    return dispatch({
      type: "PRODUCT_DETAIL_INTEGRATION",
      payload: result.data,
    });
  };
}

export function getInfoProductDetail(idProduct) {
  return async function (dispatch) {
    const source = `/products/${idProduct}`;
    const result = await getRequest(source);
    return dispatch({ type: "INFO_PRODUCT_DETAIL", payload: result.data });
  };
}

/* PRODUCTS REF*/
export function getReferencesProducts(idProduct) {
  return async function (dispatch) {
    try {
      /* result */
      const source = `products/productReferences/${idProduct}`;
      const result = await getRequest(source);
      /* cross */
      if (result.data.crosSellingSkus.length > 1) {
        const sources = result.data.crosSellingSkus.map((productId) => {
          return `/products/productId/${productId}`;
        });
        const result2 = await promiseAllRequest(sources);

        const dataMap = result2.map((product) => {
          if (product.data.name !== "DEFAULT") {
            return product.data;
          }
        });
        const dataFilter = dataMap.filter((e) => e);
        /* up */
        const sources2 = result.data.upSellingSkus.map((productId) => {
          return `/products/productId/${productId}`;
        });
        const result3 = await promiseAllRequest(sources2);
        const dataMap2 = result3.map((product) => {
          if (product.data.name !== "DEFAULT") {
            return product.data;
          }
        });
        const dataFilter2 = dataMap2.filter((e) => e);

        /* DISPATCH */
        return dispatch({
          type: "GET_CROSS_UP_SELLINGS",
          payload: { cross: dataFilter, up: dataFilter2 },
        });
      }
    } catch (error) {
      console.log("the following error has ocurred: ", error);
    }
  };
}
/* PDP PRODUCTS REF */
export function getReferencesPdp(idProduct) {
  return async function (dispatch) {
    try {
      /* resultado */
      const source = `products/productReferences/${idProduct}`;
      const result = await getRequest(source);

      /* crosspdp */

      if (result.data.crosSellingSkus.length > 1) {
        const sourcesCross = result.data.crosSellingSkus.map((productId) => {
          return `/price/b2bUnitUid/${customerId}/productId/${productId}?qty=1`;
        });
        const resultCross = await promiseAllRequest(sourcesCross);
        const dataMapCross = resultCross.map((product) => {
          if (product.data.hasOwnProperty("ZRPAData")) {
            return product.data;
          }
        });

        const dataFilterCross = dataMapCross.filter((e) => e);
        /* uppdp */
        const sourcesUp = result.data.upSellingSkus.map((productId) => {
          return `/price/b2bUnitUid/${customerId}/productId/${productId}?qty=1
          
          
          
          `;
        });
        const resultUp = await promiseAllRequest(sourcesUp);
        const dataMapUp = resultUp.map((product) => {
          if (product.data.hasOwnProperty("ZRPAData")) {
            return product.data;
          }
        });
        const dataFilterUp = dataMapUp.filter((e) => e);
        /* DISPATCH */
        return dispatch({
          type: "GET_CROSS_UP_SELLINGS_PDP",
          payload: { crossPdp: dataFilterCross, upPdp: dataFilterUp },
        });
      }
    } catch (error) {
      console.log("the following error has ocurred: ", error);
    }
  };
}

export function clearDetail() {
  return {
    type: "CLEAR_DETAIL",
    payload: {},
  };
}

export function getNuestrasMarcas() {
  return async function (dispatch) {
    const source = `/component/nuestras-marcas`;
    const result = await getRequest(source);

    return dispatch({ type: "NUESTRAS_MARCAS", payload: result.data });
  };
}

export function addProductCart(cartId, productId, quantity) {
  return async function (dispatch) {
    const source = `/users/${customerUID}/carts/${cartId}/entries?code=${productId}&fields=DEFAULT&qty=${quantity}`;
    const result = await postRequest(source);
    return dispatch({
      type: "ADD_PRODUCT",
      payload: [{ product: { code: productId } }],
    });
  };
}

export function getCartProducts() {
  return async function (dispatch) {
    const source = `/carts/${customerUID}`;
    const result = await getRequest(source);
    return dispatch({
      type: "CART_PRODUCTS",
      payload: result.data ? result.data : {},
    });
  };
}

export function getDataUser() {
  return async function (dispatch) {
    const source = `/users/users/${customerUID}`;
    const result = await getRequest(source);
    return dispatch({ type: "DATA_USER", payload: result.data });
  };
}

export function getfilter(queries) {
  return async function (dispatch) {
    const source = `/products/search?currentPage=${queries.currentPage}&customerId=${customerUID}&pageSize=${queries.pageSize}&sort=${queries.sort}&query=${queries.query}`;
    const result = await getRequest(source);
    if (queries.flagBard) {
      return dispatch({ type: "FILTER_BARD", payload: result.data });
    }
    return dispatch({ type: "FILTER", payload: result.data });
  };
}

export function clearDetailCategories() {
  return {
    type: "CLEAR_DETAIL_CATEGORIES",
    payload: {},
  };
}

export function setSubcategories(data) {
  return {
    type: "SET_SUBCATEGORY",
    payload: data,
  };
}

export function getPlaceOrder(cartCode) {
  return async function (dispatch) {
    const source = `/orders/users/${customerUID}/orders?cartId=${cartCode}&fields=DEFAULT`;
    const result = await postRequest(source);
    return dispatch({ type: "CART_PLACE_ORDER", payload: result.data });
  };
}

export function actualiceProductCart(cartId, productId, quantity, entryNumber) {
  return async function (dispatch) {
    const source = `/users/${customerUID}/carts/${cartId}/entries/${entryNumber}?fields=DEFAULT&productCodeEntry=${productId}&qty=${quantity}`;
    const result = await putRequest(source);
    return dispatch({
      type: "RESS_PRODUCT",
      payload: { product: { code: productId } },
    });
  };
}

export function removeProductCart(cartId, entryNumber) {
  return async function (dispatch) {
    const source = `/users/${customerUID}/carts/${cartId}/entries/${entryNumber}`;
    const result = await deleteRequest(source);
    return dispatch({
      type: "REMOVE_PRODUCT",
      payload: [result.data.idProduct],
    });
  };
}

export function getfrecuencia() {
  return async function (dispatch) {
    try {
      const source = `/frecuencia-despacho/dates/customer/${customerUID}`;
    const result = await getRequest(source);
    return dispatch({ type: "GET_DESPACHO", payload: result.data });
    } catch (error) {
      console.log("el carro aun no ha sido cargado");
    }
    
  };
}

// export function postfrecuencia(int, int2) {
//   return async function (dispatch) {
//     const source = `/frecuencia-despacho/dates/customer/${customerUID}?fechaFresco=${int}&fechaProcesado=${int2}`;
//     const result = await postRequest(source);
//     return dispatch({ type: "POST_DESPACHO", payload: result.data });
//   };
// }

// export function postfrecuenciaFresco(int) {
//   return async function (dispatch) {
//     const source = `/frecuencia-despacho/dates/customer/${customerUID}?fechaFresco=${int}`;
//     const result = await postRequest(source);
//     return dispatch({ type: "POST_DESPACHO", payload: result.data });
//   };
// }

export function postfrecuencia(query) {
        return async function (dispatch) {
            const source = `/frecuencia-despacho/dates/customer/${customerUID}${query}`
            const result = await postRequest(source)
            return dispatch({ type: 'POST_DESPACHO', payload: result.data})
            }
        }

export function getBaseStore() {
  return async function (dispatch) {
    const source = `/basestore/agrosuper`;
    const result = await getRequest(source);
    return dispatch({ type: "GET_BASE_STORE", payload: result.data });
  };
}
        

export function getMinimumCart(cartId) {

  return async function (dispatch) {
    const source = `/carts/${customerUID}/checkIfCanBuy/${cartId}`;
    const result = await getRequest(source);

    return dispatch({ type: "GET_MINIMUM_CART", payload: result.data });

  };

}

export function getCategoryProduct(productId) {
    return async function (dispatch) {
        const allData = await getRequest(`/category-tree/productId/${productId}`);
        return dispatch({ type: 'GET_CATEGORY_PRODUCT', payload: allData.data })
    }
}

export function getVisibleFlags() {

  return async function (dispatch) {
    const source = `/component/visible-components`;
    const result = await getRequest(source);

    return dispatch({ type: "GET_VISIBLE_FLAGS", payload: result.data });

  };

}  


export function logout() {


  return async function (dispatch) {
    const source = `/c-commerce/logout/${customerUID}`;
    sessionStorage.clear()
    localStorage.clear()
    



    const result = await postRequest(source);
    //eslint-disable-next-line no-restricted-globals
    location.href = `/c-commerce/signIn/token-de-acceso-expiro`

    return dispatch({ type: "logout" });

  };

}  

export function getCategoriesByUser() {
  return async function (dispatch) {
    let allData = await getRequest(`/category-tree/user/${customerUID}`);
     allData.data.categories = allData.data.categories.map(e=>{
      const subC = e.subcategories.filter(j=> j.title != null )
      e.subcategories = subC
      return e
    }
    )
    return dispatch({ type: "GET_CATEGORIES_USER", payload: allData.data });
  };

}


export function getProductRibbon(productId) {
  return async function (dispatch) {
      const allData = await getRequest(`/products/activeRibbon/${productId}`);
      return dispatch({ type: 'GET_PRODUCT_RIBBON', payload: allData.data })
  }
};

export function getPopUpProperties() {
  return async function (dispatch) {
      const allData = await getRequest(`/agrosuper-properties/popupProperties`);
      console.log("allData",allData)
      return dispatch({ type: 'GET_PROPERTIES_POPUP', payload: allData.data })
  }
};