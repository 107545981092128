import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { getCatalogos, getPopUpProperties, getVisibleFlags } from "../../Services/actions";
import BestSellings from "../BestSellings/BestSellings";
import Menu from "../Menu/Menu";
import CarouselBanners from "./CarouselBanners/CarouselBanners";
import style from "./Homepage.module.css"
import { NuestrasMarcas } from './NuestrasMarcas/NuestrasMarcas'
import PopUpComponent from '../PopUp/PopUpComponent'
function Homepage() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCatalogos())
    dispatch(getVisibleFlags())
    dispatch(getPopUpProperties())
  }, []);

  const {visibleFlags} = useSelector(s=>s)

  const [flagScroll, setFlagScroll] = useState(true)

  if(flagScroll){
    setFlagScroll(false)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <div>
      <PopUpComponent></PopUpComponent>
    <div className={ visibleFlags?.banners ? style.containerHomepage : style.containerHomePageFix}>
      { visibleFlags?.banners ? (<CarouselBanners />) : (null) }
      <BestSellings />
      { visibleFlags?.marcas ? (<NuestrasMarcas />) : (null) }
      <ToastContainer limit={3} className={style.toaster}/>
    </div>
    </div>
  );
        
}

export default Homepage;
