import style from "./spinner.module.css";
import agro from "../../images/agrosuper-blanco.svg";
import wsp from "../../images/logo-agrosuper-transparente.png";

export default function Spinner() {
  return (
    <div className={style.background}>
      <div className={style.ring}>
        <img className={style.logo} alt="user" src={agro} />
      </div>
      <div className={style.procesando}>Procesando su pedido...</div>
    </div>
  );
}
