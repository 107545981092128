import React from "react";
import style from "./Footer.module.css";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/agrosuper-blanco.svg";
import email from '../../images/mail-icon.svg'
import wsp from '../../images/whatsapp-logo.png'
import tel from '../../images/phone-icon.svg'

function Footer() {
  const {pathname} = useLocation()
  return (
    <footer className={style.containerFooter}>
      <div className={style.infoFooter}>
        <div className={style.containerLogCopy}>
        <div className={style.containerLogo}>
          <div className={style.logo}>
            <Link to="/">
              <img className={style.imgLogo} alt="logo" src={logo} />
            </Link>
          </div>
        </div>
        <div className={style.copyright}>
          <span className={style.span}>
            © Agrosuper 2023. Todos los derechos reservados.
          </span>
        </div>
        </div>
              <div className={style.contData}>
              <div className={style.title}>
                <span className={style.title1}>Agrosuperventas.com</span>
              </div>
              <ul className={style.lista}>
                <li type="none">
                  <button className={style.btn}>
                    <img alt="email" src={email} className={style.imgBtn} />
                    <a className={style.aLink} href={"mailto:contacto@agrosuperventas.com"} ><span className={style.text1}>
                      contacto@agrosuperventas.com
                    </span></a>
                  </button>
                </li>
              </ul>
              </div>
              <div className={style.contData}>
              <div className={style.title}>
                <span className={style.title1}>WhatsApp</span>
              </div>
              <ul className={style.lista}>
                <li type="none">
                  <button className={style.btn}>
                    <img alt="wsp" src={wsp} className={style.imgBtn} />
                    <a className={style.aLink} target="_blank" href="https://api.whatsapp.com/message/V7Y7X4NQNFHFL1?autoload=1&app_absent=0"> <span className={style.text1}>+569 71 35 4232</span></a>
                  </button>
                </li>
              </ul>
              </div>
            <div className={style.contData}>
              <div className={style.title}>
                <span className={style.title1}>Línea Servicio al Cliente</span>
              </div>
              <ul className={style.lista}>
                <li type="none">
                  <button className={style.btn}>
                    <img alt="tel" src={tel} className={style.imgBtn} />
                    <a className={style.aLink} href="tel:600 600 7777"><span className={style.text1}>600 600 7777</span></a>
                  </button>
                </li>
                <li type="none">
                  <button className={style.btn}>
                    <img alt="email" src={email} className={style.imgBtn} />
                    <a className={style.aLink} href="mailto:servicioalclienteas@agrosuper.com"><span className={style.text1}>
                    servicioalclienteas@agrosuper.com
                    </span></a>
                  </button>
                </li>
              </ul>
              </div>
              <div className={style.contData}>
              <div className={style.title}>
                <span className={style.title1}>Call Center</span>
              </div>
              <ul className={style.lista}>
                <li type="none">
                  <button className={style.btn}>
                    <img alt="tel" src={tel} className={style.imgBtn} />
                    <a className={style.aLink} href="tel:600 600 6061"><span className={style.text1}>600 600 6061</span></a> 
                  </button>
                </li>
              </ul>
              </div>
      </div>
    
    </footer>
  );
}

export default Footer;
