import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { removeProductCart, setCart, setQuantity } from "../../actions";
import {
  removeProductCart,
  setCart,
  setQuantity,
} from "../../Services/actions";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import style from "./CardCart.module.css";
import useScreenSize from "../../Hook/useScreenSize";
import { ccommerceProvider } from "../Context/CcommerceProvider";
import plusImg from "../../images/plus-solid.svg";
import minusImg from "../../images/minus-solid.svg";


const { REACT_APP_API_ADMIN } = process.env;

export default function CardCart({ product, quantityObj }) {
  const { addToCart, ressToCart, removeToCart } = useContext(ccommerceProvider);

  const { width } = useScreenSize();

  const dispatch = useDispatch();

  const { cart, cartSupport } = useSelector((s) => s);

  //const find = cartSupport.find(e=> e.idProduct === product.code)

  const [quantityCart, setQuantityCart] = useState(product?.quantity);

  //const { returnPrice } = useContext(cartContext);

  let quantity = product?.quantity;

  const handleAddQuantity = (e) => {
    setQuantityCart(quantityCart + 1);
    addToCart(product.product.code, 1);
  };

  const handleRemoveQuantity = (e) => {
    if (quantity - 1 < 1) return;
    quantity -= 1;
    setQuantityCart(quantity);
    if (quantity === 1) {
      ressToCart(product.product.code, 1, product.entryNumber);
    } else {
      ressToCart(product.product.code, quantity, product.entryNumber);
    }
  };

  const handleAddToCart = () => {
    addToCart(product.product.code, quantityCart + 1);
    setQuantityCart(1);
  };

  useEffect(() => {}, [product]);

  const handleRemove = (e) => {
    e.preventDefault();

    swal({
      title: "Estás seguro?",
      text: `Se va a eliminar ${product.product.name} del carrito!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Producto eliminado con éxito!", {
          icon: "success",
        });

        dispatch(() =>
          removeToCart(product?.product.code, product.entryNumber)
        );
      }
    });
  };

  return (
    <div className={style.card}>
      <div className={style.imagenInfo}>
        <div className={style.containerInfo}>
          <Link to={`/product/${product?.product.code}`}>
            <div className={style.containerImg}>
              <img
                className={style.img}
                src={REACT_APP_API_ADMIN + product.product.images[2].url}
                alt={product.product.name}
              ></img>
            </div>
          </Link>
        </div>

        <div className={style.containerSec}>
          <div className={style.containerData}>
            <div className={style.containerDataName}>
              <p className={style.productName}>
                {product.product.name}{" "}
                {/* <span className={style.gramos}>(730 g)</span> */}
              </p>
              <p className={style.cantPorCaja}>
                {product.product.description_2}
              </p>
            </div>
            {product.product.productDetailsData[0].descuentoAplicado ? (
              <div className={style.containerPriceXBox}>
                <p className={style.textPrice0}>
                  <span className={style.textPrice1}>
                    Precio internet por{" "}
                    {product.product.productDetailsData[0].unidadMedidaVentas}:{" "}
                  </span>{" "}
                  <span className={style.precioxBox}>
                    {product.product.productDetailsData[0].precioDescontado}
                  </span>
                </p>
                <p className={style.textPrice}>
                  <span className={style.textPrice1}>
                    Valor internet por caja:
                  </span>{" "}
                  <span className={style.precioxBox}>
                    {product.product.productDetailsData[0].precioConDescuento}
                  </span>
                </p>
              </div>
            ) : (
              <div className={style.containerPriceXBox}>
                <p className={style.textPrice0}>
                  <span className={style.textPrice1}>
                    Precio por{" "}
                    {product.product.productDetailsData[0].unidadMedidaVentas}:{" "}
                  </span>{" "}
                  <span className={style.precioxBox}>
                    {product.product.productDetailsData[0].precioNormal}
                  </span>
                </p>
                <p className={style.textPrice}>
                  <span className={style.textPrice1}>Valor por caja:</span>{" "}
                  <span className={style.precioxBox}>
                    {product.basePrice.formattedValue}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className={style.containerButtonPrice}>
        <div className={style.productPrice2}>
          <div className={style.price}>Precio neto: </div>
          <div className={style.productPrice}>
            {product.totalPrice.formattedValue}{" "}
          </div>
        </div>

        <div className={style.cantTrash}>
          <div className={style.containerButtons}>
            <div className={style.containerAdd}>
              <span onClick={handleRemoveQuantity} className={style.minus}>
              <img alt="minus" src={minusImg} className={style.minus} />
              </span>
              <span className={style.quantity}>{quantityObj}</span>
              <span onClick={handleAddQuantity} className={style.plus}>
              <img alt="plus" src={plusImg} className={style.plus} />
              </span>
            </div>
            <div onClick={handleRemove} className={style.containerTrash}>
              <img
                className={style.imgTrash}
                src={REACT_APP_API_ADMIN + "/_ui/responsive/common/images/Trash.svg"}
              />
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
  // }
}
