import { DotWave } from '@uiball/loaders';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom'
import { getBearerToken } from '../../Services/actions/auth';
import sinResultados from "../../images/sinresultados.svg"

import style from "./Auth.module.css"
export const Auth = () => {
    const dispatch = useDispatch()
    const params = useParams().tokenCCommerce;
    const user = useSelector(state => state.userSuccess)
    useEffect(() => {
        dispatch(getBearerToken(params))
    }, [user])
        if(user === "error"){
            return (
                <div className={style.product_notFound}>

                    <div className={style.containerNoProducts}>
              <div className={style.busquedaFalse + " container"}>
              <div className={style.imgyP}>
                <img src="https://agrosuperventas.com/_ui/responsive/common/images/sinresultados.svg" alt="img"></img>
              </div>
              <div className={style.ullista}>
              <ul>
                <li>Si ya realizaste una compra, el link (o enlace) que te entregamos ha expirado. Para realizar un nuevo pedido, vuelve al WhatsApp y selecciona en el menú la opción Solicitar Pedido.</li>
                <li>Si pasaron 15 minutos desde que fue provisto el link (o enlace) este ya caducó. Vuelve a WhatsApp y escoge del menú la opción Solicitar Pedido para entregarte uno nuevo.</li>
                <li>No edites el link (o enlace) que te fue enviado por WhatsApp.</li>
                <li>No podrás navegar por la página sin un link actualizado.</li>
              </ul>
              </div>
              </div>
          </div>
      </div>
            )
        }else if(user === "success"){
            //eslint-disable-next-line no-restricted-globals
            location.href="/"
        } else{

            return(
                <div className={style.containerLoading}>
                <DotWave size={70} speed={1} color="black" />
              </div>
            )
        }


}