import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CardCart from "../CardCart/CardCart";
import style from "./Cart.module.css";
import { RiHome2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCart,
  getProducts,
  getCartProducts,
  getfrecuencia,
  postfrecuencia,
  getMinimumCart,
} from "../../Services/actions";
import { cartContext } from "../Context/ContextProvider";
import { motion } from "framer-motion";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import FinalizarCompra from "./FinalizarCompra";
import useScreenSize from "../../Hook/useScreenSize";
import { ToastContainer } from "react-toastify";
import { CSVLink } from "react-csv";

const animations = {
  hidden: {
    opacity: 0,
    x: 100,
  },
  show: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: "-100vh",
    transition: {
      ease: "easeInOut",
      duration: 0.5,
    },
  },
};

export default function Cart() {
  const { setSelectedSpam, price, setPrice } =
    useContext(cartContext);

  const { width } = useScreenSize();

  const { setTrueorFalse, selected, setSelected } = useContext(cartContext);

  const { searchProd, cartSupport, cart,baseStore, listaCSV } = useSelector((state) => state);


  const products = useSelector((state) => state.cart);

  const despacho = useSelector((state) => state.frecuenciaDespacho);

  const dispatch = useDispatch();

  const [refresh, setRefresh] = useState(false)

  const history = useHistory();

  useEffect(() => {
    dispatch(getCartProducts());
    dispatch(getfrecuencia());

  }, [cartSupport]);

  const [flagCart,setFlagCart] = useState(true)

  if(cart?.code && flagCart){
    setFlagCart(false)
    dispatch(getMinimumCart(cart.code))
  }

  const clear = (e) => {
    setSelectedSpam("");
    selected ? setSelected(false) : setSelected(true);
    setTrueorFalse(false);
    dispatch(getProducts());
  };
  const handleClearCart = (e) => {
    e.preventDefault();

    if (!products.length) {
      swal("Error!", "No hay productos en el carrito!", "error");
      return;
    }

    swal({
      title: "Estás seguro?",
      text: "Se van a eliminar todos los productos de su carrito!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Carrito vaciado con éxito!", {
          icon: "success",
        });
        dispatch(clearCart());
        setPrice(0);
      }
    });
  };

  const [estadoDespacho, setEstadoDespacho] = useState({
    procesado: null,
    fresco: null,
  });

  const handleBack = () => {
    setSelectedSpam("");
    history.push("/");
  };

  const handleSelect = (e) => {
    if (e.target.name === "selectFresco") {
      let date = new Date(e.target.value).getTime();
      setEstadoDespacho({
        ...estadoDespacho,
        fresco: date,
      });
    } else {
      let date = new Date(e.target.value).getTime();
      setEstadoDespacho({
        ...estadoDespacho,
        procesado: date,
      });
    }
  };

  const handlePurchase = () => {
    if (!cart?.entries?.length) {
      swal("Error!", "No hay productos en el carrito!", "error");
      return;
    }

    // const result = products.filter((product) => product.stock === 0);

    // if (result.length) {
    //   swal("Error!", "Tienes un producto sin stock en tu carrito!", "error");
    //   return;
    // }

    if(despacho?.fechasFresco?.length !== 0 || despacho?.fechasProcesado?.length !== 0){
      if( despacho?.cartHasFresco && despacho?.cartHasProcesado ){
        
  
        let int = estadoDespacho.fresco
        let int2 = estadoDespacho.procesado
        if(estadoDespacho.fresco === null){
          int = new Date(despacho?.fechasFresco[0]?.date).getTime()
        }
        if(estadoDespacho.procesado === null){
          int2 = new Date(despacho?.fechasProcesado[0]?.date).getTime()
        }
  
        let query = `?fechaFresco=${int}&fechaProcesado=${int2}`
  
        dispatch((postfrecuencia(query)))
      }else
  
      if(despacho?.cartHasFresco){
        
        if(estadoDespacho.fresco === null){
          let date = new Date(despacho?.fechasFresco[0]?.date).getTime()
          let query = `?fechaFresco=${date}`
          return dispatch((postfrecuencia(query)))
        } else {
  
          let query2 = `?fechaFresco=${estadoDespacho.fresco}`
  
        dispatch((postfrecuencia(query2)))}
      }else
  
      if(despacho?.cartHasProcesado){
        if(estadoDespacho.procesado === null){
          let date = new Date(despacho?.fechasProcesado[0]?.date).getTime()
          let query = `?fechaProcesado=${date}`
          return dispatch((postfrecuencia(query)))
        }else {
          let query2 = `?fechaProcesado=${estadoDespacho.fresco}`
  
          dispatch((postfrecuencia(query2)))}
      }
     }
  
   

    //dispatch(purchaseCart());
  };

  const headers = [
    { label: "Sku", key: "product.code" },
    { label: "Nombre", key: "product.name" },
    { label: "Descripción", key: "product.description_2" },
    { label: "Cantidad", key: "quantity" },
    { label: "Precio", key: "totalPrice.formattedValue" },
  ];

  const data = listaCSV;

  const [flagScroll, setFlagScroll] = useState(true)

  if(flagScroll){
    setFlagScroll(false)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  if (width <= 1024) {
    return (
      <motion.div
        variants={animations}
        initial="hidden"
        animate="show"
        exit="exit"
        className={style.allCart}
      >
   <div className={style.rutas}>
        <div className={style.inicio}>
            <Link className={style.inicio} to={"/"} onClick={() => clear()}>
            <RiHome2Line className={style.logoCasa}/> INICIO
            </Link>
            <span> / </span>
            <span className={style.nameRuta}>CARRO</span>
          </div>
        </div>

        <div className={style.containerDespacho}>
          <div className={style.DespachoBotones}>
            <div className={style.despacho}>
              <span className={style.titleDespacho}>DATOS DE DESPACHO</span>
              <span className={style.horario}>
                Considera que tu pedido será ingresado a nuestro sistema el día
                de hoy hasta las {despacho.horarioRecorte} horas
                <br></br>
                Los pedidos ingresados posteriormente serán considerados al
                siguiente día hábil
              </span>

              <div className={style.subTitle}>
                {despacho?.cartHasFresco &&
                despacho?.fechasFresco?.length > 0 ? (
                  <div className={style.selectDiv}>
                    <span className={style.subt}>
                      POLLO, CERDO, PAVO, SALMÓN
                    </span>

                    <span className={style.fecha}>Seleccionar fecha:</span>
                    <select
                      onChange={handleSelect}
                      name="selectFresco"
                      className={style.selectFecha}
                      defaultValue={despacho?.fechasFresco[0].formattedDate}
                    >
                      {despacho?.fechasFresco?.map((obj, i) => (
                        <option key={i} value={obj.date}>
                          {obj.formattedDate}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div></div>
                )}
                {despacho?.cartHasProcesado &&
                despacho?.fechasProcesado?.length > 0 ? (
                  <div className={style.selectDiv}>
                    <span className={style.subt}>
                      CECINA, ELABORADOS, HORTALIZAS
                    </span>
                    <span className={style.fecha}>Seleccionar fecha:</span>
                    <select
                      onChange={handleSelect}
                      name="selectProcesado"
                      className={style.selectFecha}
                      defaultValue={despacho?.fechasProcesado[0].formattedDate}
                    >
                      {despacho?.fechasProcesado?.map((obj, i) => (
                        <option key={i} value={obj.date}>
                          {obj.formattedDate}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className={style.containerForm}>
              <FinalizarCompra              
                price={cart?.subTotal?.formattedValue}
                pvalue={cart?.subTotal?.value}
                priceTax={cart?.totalPrice?.formattedValue}
                priceTaxValue={cart?.totalPrice?.value}
                impCarne={cart?.impuestoCarne}
                ptax={cart?.totalPrice?.value}
                handlePurchase={handlePurchase} />
            </div>
            <div className={style.containerBotones}>
              <div className={style.botonesa}>
                
                <CSVLink
                  data={data}
                  headers={headers}
                  filename={"listaProductos.csv"}
                >
                 <button className={style.button}>

                  <img
                    src="https://agrosuperventas.com/_ui/responsive/common/images/descargar-lista.svg"
                    alt=""
                  />
                    <span className={style.spanBotonesa}> Descargar Lista</span>
                  </button>
                </CSVLink>

              </div>
            </div>
          </div>
        </div>

        <div className={style.listAndForm}>
          <div className={style.containerAll}>
            <div className={style.containerListProducts}>
              {cart?.entries &&
              cart?.entries.length > 0 &&
              Object.keys(cart?.entries[0]).length > 0 ? (
                cart?.entries?.map((obj, i) => (
                  <CardCart key={i} quantityObj={obj.quantity} product={obj} />
                ))
              ) : (
                <div className={style.containerCartEmpty}>
                  <span className={style.textEmptyCart}>
                    Ups! El carrito esta vacío
                  </span>
                  <svg
                    width={"300"}
                    height={"300"}
                    viewBox="0 0 120 120"
                    fill="none"
                    className={style.svgCart}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.25 101.25C43.3211 101.25 45 99.5711 45 97.5C45 95.4289 43.3211 93.75 41.25 93.75C39.1789 93.75 37.5 95.4289 37.5 97.5C37.5 99.5711 39.1789 101.25 41.25 101.25Z"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M93.75 101.25C95.8211 101.25 97.5 99.5711 97.5 97.5C97.5 95.4289 95.8211 93.75 93.75 93.75C91.6789 93.75 90 95.4289 90 97.5C90 99.5711 91.6789 101.25 93.75 101.25Z"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.25 18.75H26.25L37.5 82.5H97.5"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M37.5 67.5H95.9625C96.3961 67.5003 96.8165 67.3503 97.1519 67.0755C97.4874 66.8007 97.7172 66.4182 97.8023 65.993L104.552 32.243C104.607 31.9708 104.6 31.69 104.533 31.4207C104.466 31.1514 104.34 30.9005 104.163 30.6859C103.987 30.4713 103.766 30.2984 103.515 30.1798C103.264 30.0612 102.99 29.9998 102.712 30H30"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M54.8573 44.572C56.7508 44.572 58.2859 43.037 58.2859 41.1434C58.2859 39.2499 56.7508 37.7148 54.8573 37.7148C52.9637 37.7148 51.4287 39.2499 51.4287 41.1434C51.4287 43.037 52.9637 44.572 54.8573 44.572Z"
                      fill="#CFCFCF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M75.0136 59.727C75.8048 60.8964 77.3928 61.2097 78.5695 60.4253C79.7512 59.6375 80.0705 58.041 79.2827 56.8593L77.1431 58.2857C79.2827 56.8593 79.282 56.8584 79.2814 56.8574L79.28 56.8552L79.2769 56.8507L79.2697 56.8401L79.2514 56.8132L79.1986 56.7378C79.1562 56.6781 79.0993 56.6001 79.0278 56.5063C78.8851 56.319 78.6834 56.0676 78.4222 55.7738C77.9016 55.1881 77.1339 54.4217 76.1146 53.6571C74.0681 52.1223 70.9687 50.5714 66.8574 50.5714C62.7461 50.5714 59.6467 52.1223 57.6003 53.6571C56.5809 54.4217 55.8133 55.1881 55.2927 55.7738C55.0315 56.0676 54.8297 56.319 54.687 56.5063C54.6156 56.6001 54.5587 56.6781 54.5163 56.7378L54.4635 56.8132L54.4451 56.8401L54.438 56.8507L54.4349 56.8552L54.4335 56.8574C54.4328 56.8584 54.4322 56.8593 56.5717 58.2857L54.4322 56.8593C53.6444 58.041 53.9637 59.6375 55.1453 60.4253C56.322 61.2097 57.9101 60.8964 58.7013 59.727C58.7004 59.7282 58.7 59.7289 58.7 59.7289L58.7001 59.7287L58.7033 59.7239L58.7067 59.7189L58.7075 59.7182C58.7046 59.7223 58.7025 59.7252 58.7013 59.7269C58.7042 59.7226 58.7071 59.7183 58.71 59.714L58.7088 59.7157L58.7075 59.7182C58.7187 59.7025 58.7421 59.67 58.7778 59.6231C58.8494 59.5292 58.9691 59.3788 59.1365 59.1905C59.473 58.8119 59.9911 58.2926 60.686 57.7714C62.0681 56.7348 64.1116 55.7143 66.8574 55.7143C69.6033 55.7143 71.6467 56.7348 73.0289 57.7714C73.7238 58.2926 74.2418 58.8119 74.5784 59.1905C74.7458 59.3788 74.8655 59.5292 74.937 59.6231C74.9727 59.67 74.9962 59.7025 75.0074 59.7182C75.0103 59.7222 75.0123 59.7252 75.0135 59.7269"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M78.8573 44.572C80.7508 44.572 82.2859 43.037 82.2859 41.1434C82.2859 39.2499 80.7508 37.7148 78.8573 37.7148C76.9637 37.7148 75.4287 39.2499 75.4287 41.1434C75.4287 43.037 76.9637 44.572 78.8573 44.572Z"
                      fill="#CFCFCF"
                    />
                  </svg>
                </div>
              )}

              <div className={style.divCatalg}>
                {" "}
                <span className={style.spanCatalg}>
                  ¿Te faltó algún producto?
                </span>{" "}
                <Link className={style.inicio} to={"/"} onClick={() => clear()}>
                  {" "}
                  <button className={style.buttonCatalg}>Ir al catálogo</button>
                </Link>
              </div>

              <div className={style.containerCardData}>
                <FinalizarCompra
                  price={cart?.subTotal?.formattedValue}
                  pvalue={cart?.subTotal?.value}
                  priceTax={cart?.totalPrice?.formattedValue}
                  priceTaxValue={cart?.totalPrice?.value}
                  ptax={cart?.totalPrice?.value}
                  handlePurchase={handlePurchase}
                />
              </div>
            </div>
          </div>
          <ToastContainer limit={3} className={style.toaster}/>
        </div>
      </motion.div>
    );
  } else {
    return (
      <motion.div
        variants={animations}
        initial="hidden"
        animate="show"
        exit="exit"
        className={style.allCart}
      >
        <div className={style.rutas}>
        <div className={style.inicio}>
            <Link className={style.inicio} to={"/"} onClick={() => clear()}>
            <RiHome2Line className={style.logoCasa}/> INICIO
            </Link>
            <span> / </span>
            <span className={style.nameRuta}>CARRO</span>
          </div>
        </div>

        <div className={style.containerDespacho}>
          <div className={style.DespachoBotones}>
            <div className={style.despacho}>
              <span className={style.titleDespacho}>DATOS DE DESPACHO</span>
              <span className={style.horario}>
                Considera que tu pedido será ingresado a nuestro sistema el día
                de hoy hasta las {despacho.horarioRecorte} horas
                <br></br>
                Los pedidos ingresados posteriormente serán considerados al
                siguiente día hábil
              </span>

              <div className={style.subTitle}>
                {despacho?.cartHasFresco &&
                despacho?.fechasFresco?.length > 0 ? (
                  <div className={style.selectDiv}>
                    <span className={style.subt}>
                      POLLO, CERDO, PAVO, SALMÓN
                    </span>

                    <span className={style.fecha}>Seleccionar fecha:</span>
                    <select
                      onChange={handleSelect}
                      name="selectFresco"
                      className={style.selectFecha}
                      defaultValue={despacho?.fechasFresco[0].formattedDate}
                    >
                      {despacho?.fechasFresco?.map((obj, i) => (
                        <option key={i} value={obj.date}>
                          {obj.formattedDate}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div></div>
                )}
                {despacho?.cartHasProcesado &&
                despacho?.fechasProcesado?.length > 0 ? (
                  <div className={style.selectDiv}>
                    <span className={style.subt}>
                      CECINA, ELABORADOS, HORTALIZAS
                    </span>
                    <span className={style.fecha}>Seleccionar fecha:</span>
                    <select
                      onChange={handleSelect}
                      name="selectProcesado"
                      className={style.selectFecha}
                      defaultValue={despacho?.fechasProcesado[0].formattedDate}
                    >
                      {despacho?.fechasProcesado?.map((obj, i) => (
                        <option key={i} value={obj.date}>
                          {obj.formattedDate}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className={style.containerForm}>
              <FinalizarCompra              
                price={cart?.subTotal?.formattedValue}
                pvalue={cart?.subTotal?.value}
                priceTax={cart?.totalPrice?.formattedValue}
                priceTaxValue={cart?.totalPrice?.value}
                ptax={cart?.totalPrice?.value}
                handlePurchase={handlePurchase} />
            </div>
            <div className={style.containerBotones}>
              <div className={style.botonesa}>      

                <CSVLink
                  data={data}
                  headers={headers}
                  filename={"listaProductos.csv"}
                >
                  <img 
                    src="https://agrosuperventas.com/_ui/responsive/common/images/descargar-lista.svg"
                    alt=""
                  />
                  <button className={style.button}>
                    <span className={style.spanBotonesa}> Descargar Lista</span>
                  </button>
                </CSVLink>

              </div>
            </div>
          </div>
        </div>

        <div className={style.listAndForm}>
          <div className={style.containerAll}>
            <div className={style.containerListProducts}>
              {cart?.entries &&
              cart?.entries.length > 0 &&
              Object.keys(cart?.entries[0]).length > 0 ? (
                cart?.entries?.map((obj, i) => (
                  <CardCart key={i} quantityObj={obj.quantity} product={obj} />
                ))
              ) : (
                <div className={style.containerCartEmpty}>
                  <span className={style.textEmptyCart}>
                    Ups! El carrito esta vacío
                  </span>
                  <svg
                    width={"300"}
                    height={"300"}
                    viewBox="0 0 120 120"
                    fill="none"
                    className={style.svgCart}
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M41.25 101.25C43.3211 101.25 45 99.5711 45 97.5C45 95.4289 43.3211 93.75 41.25 93.75C39.1789 93.75 37.5 95.4289 37.5 97.5C37.5 99.5711 39.1789 101.25 41.25 101.25Z"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M93.75 101.25C95.8211 101.25 97.5 99.5711 97.5 97.5C97.5 95.4289 95.8211 93.75 93.75 93.75C91.6789 93.75 90 95.4289 90 97.5C90 99.5711 91.6789 101.25 93.75 101.25Z"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M11.25 18.75H26.25L37.5 82.5H97.5"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M37.5 67.5H95.9625C96.3961 67.5003 96.8165 67.3503 97.1519 67.0755C97.4874 66.8007 97.7172 66.4182 97.8023 65.993L104.552 32.243C104.607 31.9708 104.6 31.69 104.533 31.4207C104.466 31.1514 104.34 30.9005 104.163 30.6859C103.987 30.4713 103.766 30.2984 103.515 30.1798C103.264 30.0612 102.99 29.9998 102.712 30H30"
                      stroke="#CFCFCF"
                      strokeWidth="7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M54.8573 44.572C56.7508 44.572 58.2859 43.037 58.2859 41.1434C58.2859 39.2499 56.7508 37.7148 54.8573 37.7148C52.9637 37.7148 51.4287 39.2499 51.4287 41.1434C51.4287 43.037 52.9637 44.572 54.8573 44.572Z"
                      fill="#CFCFCF"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M75.0136 59.727C75.8048 60.8964 77.3928 61.2097 78.5695 60.4253C79.7512 59.6375 80.0705 58.041 79.2827 56.8593L77.1431 58.2857C79.2827 56.8593 79.282 56.8584 79.2814 56.8574L79.28 56.8552L79.2769 56.8507L79.2697 56.8401L79.2514 56.8132L79.1986 56.7378C79.1562 56.6781 79.0993 56.6001 79.0278 56.5063C78.8851 56.319 78.6834 56.0676 78.4222 55.7738C77.9016 55.1881 77.1339 54.4217 76.1146 53.6571C74.0681 52.1223 70.9687 50.5714 66.8574 50.5714C62.7461 50.5714 59.6467 52.1223 57.6003 53.6571C56.5809 54.4217 55.8133 55.1881 55.2927 55.7738C55.0315 56.0676 54.8297 56.319 54.687 56.5063C54.6156 56.6001 54.5587 56.6781 54.5163 56.7378L54.4635 56.8132L54.4451 56.8401L54.438 56.8507L54.4349 56.8552L54.4335 56.8574C54.4328 56.8584 54.4322 56.8593 56.5717 58.2857L54.4322 56.8593C53.6444 58.041 53.9637 59.6375 55.1453 60.4253C56.322 61.2097 57.9101 60.8964 58.7013 59.727C58.7004 59.7282 58.7 59.7289 58.7 59.7289L58.7001 59.7287L58.7033 59.7239L58.7067 59.7189L58.7075 59.7182C58.7046 59.7223 58.7025 59.7252 58.7013 59.7269C58.7042 59.7226 58.7071 59.7183 58.71 59.714L58.7088 59.7157L58.7075 59.7182C58.7187 59.7025 58.7421 59.67 58.7778 59.6231C58.8494 59.5292 58.9691 59.3788 59.1365 59.1905C59.473 58.8119 59.9911 58.2926 60.686 57.7714C62.0681 56.7348 64.1116 55.7143 66.8574 55.7143C69.6033 55.7143 71.6467 56.7348 73.0289 57.7714C73.7238 58.2926 74.2418 58.8119 74.5784 59.1905C74.7458 59.3788 74.8655 59.5292 74.937 59.6231C74.9727 59.67 74.9962 59.7025 75.0074 59.7182C75.0103 59.7222 75.0123 59.7252 75.0135 59.7269"
                      fill="#CFCFCF"
                    />
                    <path
                      d="M78.8573 44.572C80.7508 44.572 82.2859 43.037 82.2859 41.1434C82.2859 39.2499 80.7508 37.7148 78.8573 37.7148C76.9637 37.7148 75.4287 39.2499 75.4287 41.1434C75.4287 43.037 76.9637 44.572 78.8573 44.572Z"
                      fill="#CFCFCF"
                    />
                  </svg>
                </div>
              )}

              <div className={style.divCatalg}>
                {" "}
                <span className={style.spanCatalg}>
                  ¿Te faltó algún producto?
                </span>{" "}
                <Link className={style.inicio} to={"/"} onClick={() => clear()}>
                  {" "}
                  <button className={style.buttonCatalg}>Ir al catálogo</button>
                </Link>
              </div>
            </div>
            <div className={style.containerCardData}>
              <FinalizarCompra
                price={cart?.subTotal?.formattedValue}
                pvalue={cart?.subTotal?.value}
                priceTax={cart?.totalPrice?.formattedValue}
                priceTaxValue={cart?.totalPrice?.value}
                ptax={cart?.totalPrice?.value}
                handlePurchase={handlePurchase}
                despacho={despacho}
              />
            </div>
          </div>
        </div>
        <ToastContainer limit={3} className={style.toaster} />
      </motion.div>
    );
  }
}
