import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Paginate from "../Paginate/Paginate";
import style from "./Home.module.css";
import { DotWave } from "@uiball/loaders";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useScreenSize from "../../Hook/useScreenSize";
import { clearDetailCategories, getCatalogos, getfilter } from "../../Services/actions";
import { useParams } from "react-router-dom";
import { FilterBar } from "../FilterBar/FilterBar";
import {OrderBar} from "../FilterBar/OrderBar/OrderBar"
import CardBestSellings from "../BestSellings/CardBestSellings/CardBestSellings";
const url = process.env.REACT_APP_API_ADMIN;

export default function Home() {
  const { productsByfilters, dropDown } = useSelector((state) => state);
  const orderQuery = localStorage.getItem("order")
  const queryLocal = localStorage.getItem("query");
  const { width } = useScreenSize()
  const dispatch = useDispatch()
  const params = useParams().idCat;

  let queries = {
    currentPage: 0,
    pageSize: 20,
    sort: "relevance",
    query: ":DEFAULT"
  }

  useEffect(() => {
    dispatch(getCatalogos())
    if(params && !queryLocal){
      queries.query = `:DEFAULT:allCategories:${params}`
    }
  
    if (queryLocal) {
      queries.query = queryLocal;
    }

    if(orderQuery){
        queries.sort = orderQuery;
    }
    
    dispatch(getfilter(queries))
    localStorage.removeItem("banderaQuery")
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    return () => {

      /* dispatch(clearDetailCategories()); */
    };
  }, [params, orderQuery, queryLocal]);
/* Restructuracion de datos para productos por categoria */

  const productosMap = productsByfilters?.map(e=>{
    return{
      ...e,
      flagContainer: true,
      sku:e.code,
      productMedia:{
        url:url + e.images[1]?.url
      },
      descripcion:e.description?.split("<")[0],
      pdpData:{
        precioSinDescuento: e.price?.formattedValue,
        descuentoAplicado: e?.productDetailsData[0]?.descuentoAplicado,
        precioConDescuento: e?.productDetailsData[0]?.precioConDescuento,
        precioDescontado: e?.productDetailsData[0]?.precioDescontado,
        precioNormal: e?.productDetailsData[0]?.precioNormal,
        unidadMedidaVentas: e?.productDetailsData[0]?.unidadMedidaVentas,
        hasERPPrice: e?.productDetailsData[0]?.hasERPPrice
      }
    }
  })

  return (
    <div className={style.allHome}>
      {width >= 769 ? (
        <div className={style.containerAll}>
          <div className={style.filter}>
            <div className={style.filterChico}>
              <FilterBar />
            </div>
          </div>

          {dropDown?.products?.length === 0 && !productsByfilters.length  ? (
            <div className={style.containerNoProducts}>
              <div className={style.containerLoading}>
                <div className={style.busquedaFalse}>
                  <div className={style.imgyP}>
                    <img
                      src="https://agrosuperventas.com/_ui/responsive/common/images/sinresultados.svg"
                      alt="img"
                    ></img>

                    <p> No hay publicaciones que coincidan con tu búsqueda.</p>
                  </div>
                  <div className={style.ullista}>
                    <ul>
                      <li>Revisa la ortografía de la palabra.</li>
                      <li>Utiliza palabras más genéricas o menos palabras.</li>
                      <li>
                        Navega por las categorías para encontrar un producto similar.
                      </li>
                      <li>
                        Para otras consultas, cierra sesión, regresa a Whatsapp y selecciona del Menú Asesoría Venta Digital.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : !productsByfilters.length ? (
            <div className={style.containerNoProducts}>
              <div className={style.containerLoading}>
                <DotWave size={70} speed={1} color="black" />
              </div>
            </div>
          ) : (
            <div className={style.containerCardPaginate}>
              <div className={style.order}>
                <div>
                  <OrderBar />
                </div>
              </div>

              <div className={style.cards}>
                <div className={style.containerDivCards + " " + "container"}>
                  <div className={style.containerCards}>
                    {productosMap?.map((p, i) => (
                      <CardBestSellings key={i} product={p} productPdp={p} />
                    ))}
                  </div>
                </div>
              </div>

              <div className={style.containerPaginate}>
                <Paginate />
              </div>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className={style.filter}>
            <div>
              <FilterBar />
            </div>
            <div>
              <OrderBar />
            </div>
          </div>
          <div className={style.containerAll}>
            {dropDown?.products?.length === 0 ? (
              <div className={style.containerNoProducts}>
                <div className={style.containerLoading}>
                  <div className={style.busquedaFalse}>
                    <div className={style.imgyP}>
                      <img
                        src="https://agrosuperventas.com/_ui/responsive/common/images/sinresultados.svg"
                        alt="img"
                      ></img>

                      <p>
                        {" "}
                        No hay publicaciones que coincidan con tu búsqueda.
                      </p>
                    </div>
                    <div className={style.ullista}>
                      <ul>
                        <li>Revisa la ortografía de la palabra.</li>
                        <li>
                          Utiliza palabras más genéricas o menos palabras.
                        </li>
                        <li>
                          Navega por las categorías para encontrar un producto similar.
                        </li>
                        <li>
                          Para otras consultas, cierra sesión, regresa a Whatsapp y selecciona del Menú Asesoría Venta Digital.                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : !productsByfilters.length ? (
              <div className={style.containerNoProducts}>
                <div className={style.containerLoading}>
                  <DotWave size={70} speed={1} color="black" />
                </div>
              </div>
            ) : (
              <div className={style.containerCardPaginate}>
                <div className={style.containerDivCards + " " + "container"}>
                  <div className={style.containerCards}>
                    {productosMap?.map((p, i) => (
                      <CardBestSellings key={i} product={p} productPdp={p} />
                    ))}
                  </div>
                </div>
                <div className={style.containerPaginate}>
                  <Paginate />
                </div>
              </div>
            )}
          </div>
        </>
      )}

      <ToastContainer limit={3} className={style.toaster} />
    </div>
  );
}