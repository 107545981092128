import React, {  useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearDetailCategories, getCategories, getCategoriesByUser } from "../../Services/actions";
import style from "./Menu.module.css";
import { ccommerceProvider } from "../Context/CcommerceProvider";
import SearchBar from "../SearchBar/SearchBar";

export const Menu = () => {

  const categoria = useSelector(state => state.categories)

  const dispatch = useDispatch();
  const {  selectedOrder, setSelectedOrder } = useContext(ccommerceProvider);


  useEffect(() => {
    dispatch(getCategoriesByUser());
  }, [dispatch]);


  const onclick=(id)=>{
    dispatch(clearDetailCategories());
    localStorage.removeItem("query")
    localStorage.removeItem("order")
    setSelectedOrder(!selectedOrder) 
    };
    
  return (
    <div>
      <div className={style.ghostMenu}></div>
    <div className={style.categoriesContainer}>
      <div className={style.containerCategorias}>
        <ul className={style.cate}>
        {categoria.categories?.map((e, i) => {
          return (
                <li onClick={()=> onclick()} className={style.menuItemC} key={i}>
                   <Link  to={`${e.link}`} className={style.categories} > 
                    <span className={style.cateA}>
                      <span className={style.spanMenu} id="clickes" value="hola" >{e.title}</span>
                    </span>
                  </Link> 
                  <ol className={style.subMenu}>
                    {e.subcategories.map((f, g) => {
                      return (
                        <li onClick={()=> onclick()} className={style.menuItem} key={g}>
                          <Link to={`${f.link}`}>
                            <span className={style.subcateA}>{f.title}</span>
                          </Link>
                        </li>
                      );
                    })}
                  </ol>
                </li>
          );
        })}
        </ul>
      </div>
      <div className={style.containerBuscador}>
        <SearchBar />
      </div>
    </div>
    </div>
  );
};

export default Menu;