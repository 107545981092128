import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  clearDetail,
  getInfoProductDetail,
  getCatalogos,
  getProductDetailIntegration,
  getReferencesPdp,
  getReferencesProducts,
  getCategoryProduct,
  getProductRibbon,
} from "../../Services/actions";
import style from "./CardDetail.module.css";
import { FiCheck } from "react-icons/fi";
import { DotWave } from "@uiball/loaders";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TablaDescuento from "./TablaDescuento/TablaDescuento";
import Tabs from "./Tabs/Tabs";
import CarrouselUpSelling from "../CarrouselUpSellings/CarrouselUpSellings";
import CarrouselCrossSelling from "../CarrouselCrossSelling/CarrouselCrossSelling";
import { ccommerceProvider } from "../Context/CcommerceProvider";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import InnerImageZoom from "react-inner-image-zoom";
import BreadCrumb from "../BreadCrumb/BreadCrumb";
import dpvImg from "../../images/dpv-etiqueta.svg";
import plusImg from "../../images/plus-solid.svg";
import minusImg from "../../images/minus-solid.svg";


export default function CardDetail(props) {
  const dispatch = useDispatch();
  const idProduct = useParams().idProduct;
  const url = process.env.REACT_APP_API_ADMIN;
  const { catalogo, productsBestSellings, infoProductDetail, CrossUpSellings, productRibbon } =
    useSelector((s) => s);
  const { bestProducts } = useContext(ccommerceProvider);
  const [flagScroll, setFlagScroll] = useState(true);

  const discountVol = useSelector((s) => s.productDetailDiscount);
  const resultDpv = Object.keys(discountVol);

  if (flagScroll) {
    setFlagScroll(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const productDetailIntegration = useSelector(
    (state) => state.productDetailIntegration
  );

  const [selectedImg, setSelectedImg] = useState();

  const [flag, setFlag] = useState(true);

  // agregar al carrito

  const { addToCart } = useContext(ccommerceProvider);

  const [quantityCart, setQuantityCart] = useState(1);

  const handleAddQuantity = (e) => {
    e.preventDefault();

    // descomentar cuando haya un stock
    // if (quantityCart + 1 > obj.stock) return;

    setQuantityCart(quantityCart + 1);
    dispatch(getProductDetailIntegration(idProduct, quantityCart + 1));
  };

  const handleRemoveQuantity = (e) => {
    e.preventDefault();

    if (quantityCart - 1 < 1) return;

    setQuantityCart(quantityCart - 1);
    dispatch(getProductDetailIntegration(idProduct, quantityCart - 1));
  };

  const handleAddToCart = () => {
    addToCart(infoProductDetail.code, quantityCart);
    setQuantityCart(1);
  };

  useEffect(() => {
    if (catalogo.length && !productsBestSellings.length) {
      bestProducts();
    } else if (!catalogo.length && !productsBestSellings.length) {
      dispatch(getCatalogos());
    }
    dispatch(getProductDetailIntegration(idProduct, quantityCart));
    dispatch(getInfoProductDetail(idProduct));
    dispatch(getCategoryProduct(idProduct));

    if (idProduct) {
      dispatch(getReferencesProducts(idProduct));
      dispatch(getReferencesPdp(idProduct));
    }

    dispatch(getProductRibbon(idProduct));


    return () => {
      dispatch(clearDetail());
    };
  }, [dispatch, idProduct, catalogo]);

  if (
    infoProductDetail === undefined ||
    !Object.keys(infoProductDetail)?.length
  ) {
    return (
      <div className={style.containerLoading}>
        <DotWave size={70} speed={1} color="black" />
      </div>
    );
  } else if (
    infoProductDetail?.code !== "DEFAULT" &&
    Object.keys(infoProductDetail)?.length
  ) {
    return (
      <div className={style.containerDetail}>
        <BreadCrumb />
        <div className={style.containerForm}>
          <div className={style.containerGalery}>
            <div className={style.container + " container"}>
              <div className={style.cucardaDiscount}>
              {productRibbon?.image?.url ? <img src={productRibbon?.image?.url}/> : <div></div>}
                {resultDpv.length ? (
                  <img alt="imgDpv" src={dpvImg} className={style.imgDpv} />
                ) : (
                  <div></div>
                )}
              </div>
              <InnerImageZoom
                className={style.selected}
                zoomType={"hover"}
                zoomScale={0.6}
                hideHint={true}
                hideCloseButton={true}
                src={selectedImg || url + infoProductDetail?.images[0].url}
                zoomSrc={selectedImg || url + infoProductDetail?.images[0].url}
              />

              {/* <div className={style.scroll}>
              <div className={style.imgContainer}>
                {
                  infoProductDetail?.images.length && <>
                    <img
                      className={style.img}
                      src={url + infoProductDetail?.images[0].url}
                      alt={infoProductDetail?.images[0].altText}
                    />
                  </>
                }
              </div>
            </div> */}
            </div>
          </div>
          {productDetailIntegration?.pdpData?.hasERPPrice ?
            <div className={style.info}>
              <div className={style.code}>Código: {idProduct}</div>
              <div className={style.title}>{infoProductDetail.name}</div>
              <div className={style.description}>
                {infoProductDetail.description_2}
              </div>
              <>
                {productDetailIntegration?.pdpData?.descuentoAplicado === true ? (
                  <div className={style.precios}>
                    <div className={style.gs}>
                      Precio Normal :{" "}
                      <span className={style.price3}>
                        {productDetailIntegration?.pdpData?.precioNormal}
                      </span>
                      <span> Neto</span>{" "}
                      <span className={style.discountPercentage}>
                        {productDetailIntegration.pdpData.porcentajeDescuento} Off
                      </span>
                    </div>
                    <div className={style.gs}>
                      Precio internet por{" "}
                      {productDetailIntegration?.pdpData?.unidadMedidaVentas} :{" "}
                      <span className={style.price2}>
                        {productDetailIntegration?.pdpData?.precioDescontado}
                      </span>
                      <span> Neto</span>
                    </div>
                    <div className={style.gs}>
                      Valor internet por caja :{" "}
                      <span className={style.price}>
                        {productDetailIntegration?.pdpData?.precioConDescuento}
                      </span>{" "}
                      <span style={{ color: "#e68e4b" }}> Neto</span>
                    </div>
                    <div className={style.oferta_vigente}>
                      Oferta vigente hasta el{" "}
                      <span>
                        {productDetailIntegration?.pdpData?.fechaValidez}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={style.precios}>
                    <div className={style.gs}>
                      Precio por{" "}
                      {productDetailIntegration?.pdpData?.unidadMedidaVentas} :{" "}
                      <span className={style.price2}>
                        {productDetailIntegration?.pdpData?.precioNormal}
                      </span>
                      <span> Neto</span>
                    </div>
                    <div className={style.gs}>
                      Valor por caja :{" "}
                      <span className={style.price}>
                        {" "}
                        {productDetailIntegration?.pdpData?.precioSinDescuento}
                      </span>{" "}
                      <span style={{ color: "#e68e4b" }}> Neto</span>
                    </div>
                  </div>
                )}
              </>
  
              <div className={style.despacho}>
                <span>
                  <i>
                    <FiCheck />
                  </i>
                  Despacho{" "}
                </span>
              </div>
              <div className={style.containerBut}>
                <div className={style.containerAdd}>
                  <span onClick={handleRemoveQuantity} className={style.minus}>
                  <img alt="minus" src={minusImg} className={style.minus} />
                  </span>
                  <span className={style.quantity}> {quantityCart}</span>
                  <span onClick={handleAddQuantity} className={style.plus}>
                  <img alt="plus" src={plusImg} className={style.plus} />
                  </span>
                </div>
                <button
                  onClick={() => handleAddToCart()}
                  className={style.button}
                >
                  Agregar
                </button>
              </div>
              <div className={style.texto_info_cardDetail}>
                Los descuentos aplicados se visualizaran en su carro de compra.
              </div>
            </div>:
            <div className={style.info}>
              <div className={style.code}>Código: {idProduct}</div>
              <div className={style.title}>{infoProductDetail.name}</div>
              <div className={style.description}>
                {infoProductDetail.description_2}
              </div>
              <div className={style.containerBut}>
                <span className={style.productNotAvailable}>
                  Producto no disponible
                </span>
              </div>
            </div>
          }

          <ToastContainer limit={3} className={style.toaster} />
        </div>
        {productDetailIntegration?.pdpData?.hasERPPrice ?
        <TablaDescuento />
        : <div></div>}
        {CrossUpSellings?.crossProducts?.length ? (
          <CarrouselCrossSelling />
        ) : null}

        {CrossUpSellings?.upProducts?.length ? <CarrouselUpSelling /> : null}
        <Tabs />
      </div>
    );
  } else {
    return (
      <div className={style.product_notFound}>
        <div className={style.containerNoProducts}>
          <div className={style.containerLoading}>
            <div className={style.busquedaFalse + " container"}>
              <div className={style.imgyP}>
                <img
                  src="https://agrosuperventas.com/_ui/responsive/common/images/sinresultados.svg"
                  alt="img"
                ></img>

                <p> No hay productos que coincidan con tu búsqueda.</p>
              </div>
              <div className={style.ullista}>
                <ul>
                  <li>Revisa el código del producto.</li>
                  <li>
                    Navega por las categorías para encontrar un producto similar
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
