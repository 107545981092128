import axios from "axios";
//const token = process.env.REACT_APP_TOKEN
const token = localStorage.getItem("AUTH_TOKEN")
const ccommerceToken = localStorage.getItem("TOKEN_CCOMMERCE")
const url = process.env.REACT_APP_API


export const axiosInstanceToken = axios.create({
    baseURL: url
})



const axiosInstance = axios.create({
    baseURL: url,
    headers:{ 
        "currentSite":"c-commerce-site",
        "authorization": `Bearer ${token}`,
        "cCommerceToken": ccommerceToken
    }
})

axios.interceptors.request.use(config=>{
    return config;
})

export default axiosInstance;