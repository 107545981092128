import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import style from "./NuestrasMarcas.module.css";
import {Autoplay} from 'swiper';
import { useDispatch, useSelector } from 'react-redux';
import { getNuestrasMarcas } from '../../../Services/actions';

export const NuestrasMarcas = () => {

    const url = process.env.REACT_APP_API_ADMIN

    const dispatch = useDispatch()

    const {nuestrasMarcas} = useSelector(s=>s)

    useEffect(()=>{
        dispatch(getNuestrasMarcas())
    },[dispatch])
    return (
        <div className={style.conteinerCarrouselMarcas + ' ' + "pt-5 mt-5 pb-5" } >
            <h3>Nuestras Marcas</h3>
        <Swiper
        className={"container"}
        modules={[Autoplay]}
        grabCursor={true}
        
            autoplay={{delay: 3000}}
            spaceBetween={1}
            slidesPerView={5}
            breakpoints={{
                '1440': {
                    slidesPerView: 5,
                    spaceBetween: 1,
                },
                '1366': {
                    slidesPerView: 5,
                    spaceBetween: 1,
                },
                '1024': {
                    slidesPerView: 5,
                    spaceBetween: 1,
                },
                '769': {
                    slidesPerView: 5,
                    spaceBetween: 1,
                },
                '768': {
                    slidesPerView: 4,
                    spaceBetween: 100,
                },
                '480': {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                '320': {
                    slidesPerView: 2,
                    spaceBetween: 1,
                },
                '1': {
                    slidesPerView: 1,
                    spaceBetween: 1,
                },
                
            }}
        >
            {nuestrasMarcas && nuestrasMarcas.map((e,i) => <SwiperSlide className={style.swiperSlide} key={i}> <a href={e.url} target="_blank"><img src={url + e.mediaUrl} alt={e.marca} width="100px"></img> </a></SwiperSlide>)}
        </Swiper>
        </div>
    )
}
