import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import style from "./BreadCrumb.module.css"
import { RiHome2Line } from "react-icons/ri";

export default function BreadCrumb({idProduct}) {
    const {categoryProduct} = useSelector(s=>s)

    const categoryProductMap = categoryProduct ? categoryProduct.map(e=>{
        let link = e.url;
        link = link.split("/")
        link = `/${link[link.length - 2]}/${link[link.length - 1]}`
        e.url = link
        e.name = e.name.toUpperCase()
        return e
    }) : null
  return (
    <div className={style.containerBreadCrumb }>
        <nav aria-label="breadcrumb">
  <ol className={style.listaBread + " breadcrumb"}>
    <li className={style.noActive + " breadcrumb-item"}><Link className={style.link} to={"/"}><RiHome2Line className={style.logoCasa}/> INICIO</Link></li>
    {categoryProductMap ? categoryProductMap?.map((e, i)=>(
        e.linkClass ? (
            <li key={i} className={style.active + " breadcrumb-item"}>{e.name}</li>
        ):(
            <li key={i} className={style.noActive + " breadcrumb-item"}><Link className={style.link} to={e.url}>{e.name}</Link></li>
        )
    )):(null)}
  </ol>
</nav>
    </div>
  )
}
