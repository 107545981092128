import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getfilter } from '../../../Services/actions';
import { ccommerceProvider } from '../../Context/CcommerceProvider';
import style from "./OrderBar.module.css";

export const OrderBar = () => {
    const params = useParams().idCat;
    const dispatch = useDispatch()
    const { sort } = useSelector(state => state)
   
    const { selectedOrder, setSelectedOrder } = useContext(ccommerceProvider);
    const queryLocal = localStorage.getItem("query")
    const orderQuery = localStorage.getItem("order")

    let queries = {
        currentPage: 0,
        pageSize: 20,
        sort: "relevance",
        query: ":DEFAULT"
    }
    
    const currentOrdering = sort?.find(e=>e.selected ===true)
    useEffect(() => {

    }, [selectedOrder])
    
    const sortPrice = (e) => {
        e.preventDefault()
        console.log("sortprice");
        /* if(values.name?.length > 0) setfAplicado({ name: values.subcat, id: values.id }) */
        queries.sort = e.target.value
        localStorage.setItem("order",queries.sort)
        if (queryLocal) {
            queries.query = queryLocal
            dispatch(getfilter(queries))
            selectedOrder ? setSelectedOrder(false) : setSelectedOrder(false);
        } else if (params) {
            queries.query = `:DEFAULT:allCategories:${params}`
            dispatch(getfilter(queries))
            selectedOrder ? setSelectedOrder(false) : setSelectedOrder(false);
        }
    }
    return (
        <div className={style.orderContainer}>
            <div className={style.activeOrder}>
               <span className={style.titleactiveorder}> Ordenamiento activo:</span>
              <span className={style.txtactiveorder}> {currentOrdering?.name}</span>
            </div>
            {/* <label className={style.labelOr}> Ordenar por:</label> */}
            <div className={style.selectorCont}>
                <select className={style.selector} name="selector" onChange={(e) => sortPrice(e)}>
                    <option className={style.selectorItem} selected={selectedOrder} value="default"> Ordenar por : </option>
                    {sort?.map((e, i) => {
                        return (
                            <option key={i} className={style.selectorItem} value={e.code}>{e.name}</option>
                        )
                    })}
                </select>
            </div>
        </div>
    )
}
