import axios from "axios";
import React, { useState } from "react";
import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { actualiceProductCart, addProductCart, getProductsPdp, getProductsSku, removeProductCart } from "../../Services/actions";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const ccommerceProvider = createContext();

export default function CcommerceProvider({ children }) {

  const dispatch= useDispatch()
  const {catalogo, cart} = useSelector(s=>s)
  const [selectedOrder,setSelectedOrder] = useState(true)
  const [flagCartProd,setflagCartProd] = useState(false)
  const bestProducts = () => {
  
    const catalogoFIltered = catalogo?.filter((arr) => arr.isBestSelling);

    let catalogoSorted = catalogoFIltered.sort(
      (a, b) => b.prioridad - a.prioridad
    );

    catalogoSorted = catalogoSorted[0];
    dispatch(getProductsSku(catalogoSorted?.skus));
    dispatch(getProductsPdp(catalogoSorted?.skus));
  };

  const addToCart = (idProduct,quantity)=>{
    const products = cart.entries
    const result = products ? products.find(entries=> entries.product.code === idProduct) : undefined
    const cartId = cart.code
    if(!products){
      setflagCartProd(true)
    }
    if(result){
      dispatch(addProductCart(cartId,idProduct,quantity))     
       toast.success(`Cantidad agregada: ${quantity}`, {
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored"
         });

      return
    }
    
    dispatch(addProductCart(cartId,idProduct,quantity))

    toast.success('Agregado al carrito!', {
      position:"top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored"
      });
  }

  const ressToCart = (idProduct,quantity,entryNumber)=>{
    const products = cart.entries
    const result = products ? products.find(entries=> entries.product.code === idProduct) : undefined
    const cartId = cart.code
    

    if(result){
      dispatch(actualiceProductCart(cartId,idProduct,quantity,entryNumber))     
       toast.success(`Cantidad removida: ${1}`, {
        position:"top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
        theme: "colored"
         });

      return
    }
    
    dispatch(actualiceProductCart(cartId,idProduct,quantity,entryNumber))

    toast.success('Agregado al carrito!', {
      position:"top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "colored"
      });
  }


  const removeToCart = (idProduct,entryNumber)=>{
    const products = cart.entries
    const result = products ? products.find(entries=> entries.product.code === idProduct) : undefined
    const cartId = cart.code
    

    if(result){
      dispatch(removeProductCart(cartId,entryNumber))     
      //  toast.success(`Producto eliminado del carrito`, {
      //   position:"top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: false,
      //   draggable: true,
      //   progress: undefined,
      //   theme: "colored"
      //    });

      return
    }
    
    dispatch(removeToCart(cartId,entryNumber))

    // toast.success('Agregado al carrito!', {
    //   position:"top-right",
    //   autoClose: 2000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: false,
    //   draggable: true,
    //   progress: undefined,
    //   theme: "colored"
    //   });
  }
   

  return (
    <ccommerceProvider.Provider

      value={{selectedOrder,setSelectedOrder,bestProducts,addToCart,ressToCart,removeToCart,flagCartProd,setflagCartProd}}

    >
      {" "}
      {children}{" "}
    </ccommerceProvider.Provider>
  );
}

