import style from "./FinalizacionOrden.module.css";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { cartContext } from "../Context/ContextProvider";
import { Link } from "react-router-dom";
import useScreenSize from "../../Hook/useScreenSize";
import circleCheck from "../../images/circle-check-solid.svg"


export default function FinalizacionOrden() {
  const { setTrueorFalse, selected, setSelected } = useContext(cartContext);
  const { width } = useScreenSize();
  const { setSelectedSpam} =useContext(cartContext);
  const cartPlaceOrder = useSelector(state => state.cartPlaceOrder)
  localStorage.setItem("ordercart", JSON.stringify(cartPlaceOrder))
  const clear = (e) => {
    setSelectedSpam("");
    selected ? setSelected(false) : setSelected(true);
    setTrueorFalse(false);
  };
  const imprimir = () => {
    if(width<=768){
      localStorage.setItem("bandera","true")
    }else{
      localStorage.setItem("bandera","false")
    }
    
    setTimeout(() => {
      sessionStorage.removeItem("TOKEN_CCOMMERCE")
    sessionStorage.removeItem("AUTH_TOKEN")
    sessionStorage.removeItem("B2BCUSTOMER_UID")
    //eslint-disable-next-line no-restricted-globals
    location.href = "/c-commerce/signIn/token-de-acceso-expiro";
    }, 5000);
    
  };
  useEffect(() => {

    return()=>{
      sessionStorage.removeItem("TOKEN_CCOMMERCE")
    sessionStorage.removeItem("AUTH_TOKEN")
    sessionStorage.removeItem("B2BCUSTOMER_UID")
    //eslint-disable-next-line no-restricted-globals
    location.href = "/c-commerce/signIn/token-de-acceso-expiro";
    }
  }, [])

  const [flagScroll, setFlagScroll] = useState(true)

  if(flagScroll){
    setFlagScroll(false)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

if(!Object.keys(cartPlaceOrder).length){
  sessionStorage.removeItem("TOKEN_CCOMMERCE")
  sessionStorage.removeItem("AUTH_TOKEN")
  sessionStorage.removeItem("B2BCUSTOMER_UID")
    //eslint-disable-next-line no-restricted-globals
    location.href = "/c-commerce/signIn/token-de-acceso-expiro";
}else{
    return (
    <div className={style.container}>
      <div className={style.container2}>
        <div className={style.icon}>
          <img src={circleCheck} />
        </div>
        <div className={style.title}>Tu pedido se generó con éxito</div>

        <div className={style.contPedido}>
          <div className={style.subtitle}>
            N° de pedido:{" "}
            <span className={style.subtitleBlue}>{cartPlaceOrder?.code}</span>
          </div>
          <div className={style.subtitle2}>
            Has seguimiento con tu número del local:
          </div>
          <div className={style.contNumero}>
            <div className={style.subtitleBlue2}>{cartPlaceOrder?.user?.uid.slice(2)}</div>
          </div>
        </div>

        <div className={style.contPedido}>
          <div>
            Hemos enviado un correo de confirmación a tu mail{" "}
            <span className={style.textBold}>{cartPlaceOrder?.deliveryAddress?.email}, </span> con el
            detalle de tu compra.
          </div>
          <div>
            Revisa el estado de tu pedido en la sección "Mis Pedidos" de tu
            cuenta.
          </div>
        </div>

        <div className={style.contPedido}>
          <div className={style.red}>
            *Tu pedido está sujeto a disponibilidad de stock y validación de
            condiciones crediticas.
          </div>
        </div>

        <div className={style.contComunicarte}>
          <div className={style.textBlue}>
            Para comunicarte con Agrosuper Ventas:
          </div>
          <div>
            <LocalPhoneOutlinedIcon /> Llama al{" "}
            <span className={style.textBold}>600 600 7777</span>
          </div>
          <div>
            <ChatOutlinedIcon /> Deja un mensaje en el{" "}
            <span className={style.textBold}>chat online</span>
          </div>
          <div>
            <WhatsAppIcon /> Conéctate con nuestro{" "}
            <span className={style.textBold}>Whatsapp +56 9 7135 4232</span>
          </div>
          <div>
            <HelpOutlineOutlinedIcon /> Dirígete a la sección{" "}
            <span className={style.textBold}>"Ayuda"</span>{" "}
          </div>
        </div>

        <div className={style.contResumen}>
          <div className={style.titleResumen}>Resumen del pedido</div>

          <div className={style.contNroPagar}>
            <div className={style.nro}>
              <div className={style.padd}>
                <div>Número del pedido:</div>
                <div className={style.textBold}>{cartPlaceOrder?.code}</div>
              </div>

              <div className={style.padd}>
                <div>Número de local:</div>
                <div className={style.textBold}>{cartPlaceOrder?.user?.uid.slice(2)}</div>
              </div>
              {cartPlaceOrder?.diaDespachoFresco ? <div className={style.padd}>
                <div>Fecha de despacho fresco:</div>
                <div className={style.textBold}>{cartPlaceOrder?.diaDespachoFresco}</div>
              </div>:null}

             {cartPlaceOrder?.diaDespachoProcesado ? <div className={style.padd}>
                <div>Fecha de despacho procesado:</div>
                <div className={style.textBold}>{cartPlaceOrder?.diaDespachoProcesado}</div>
              </div>:null}

              <div>
                <div>Dirección del despacho: </div>
                <div className={style.textBold}>
                  {cartPlaceOrder?.deliveryAddress?.formattedAddress}
                </div>
              </div>
            </div>

            <div className={style.pagar}>
              <div className={style.padd2}>
                <div className={style.subtitle}>A pagar:</div>
                <div className={style.subtOrange}>{cartPlaceOrder?.totalPrice?.formattedValue}*</div>
                <div className={style.miniText}>
                  *Valores de referencia pueden variar por el peso del producto
                </div>
              </div>

              <div>
                <div className={style.ahorraste}>En esta compra ahorraste:</div>
                <div className={style.ahorrasteBlue}>{cartPlaceOrder?.totalDiscounts?.formattedValue}</div>
              </div>
            </div>
          </div>
        </div>


        <a href={`/print/${cartPlaceOrder?.code}`} target="_blank">
          <button className={style.button} onClick={imprimir}>
            Imprimir Pedido
          </button>
        </a>
        <div>
          <Link className={style.inicio} to={"/"} onClick={() => clear()}>
            Volver al inicio
          </Link>
        </div>
      </div>
    </div>
  );
}

}
