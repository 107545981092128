import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getDataUser, getPlaceOrder } from "../../Services/actions";
import { cartContext } from "../Context/ContextProvider";
import style from "./Checkout.module.css";
import ResumenOrden from "./ResumenOrden";
import useScreenSize from "../../Hook/useScreenSize";
import PaidIcon from "@mui/icons-material/Paid";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import Spinner from "../../shared/spinner/spinner";
import { useHistory } from "react-router-dom";
import { RiHome2Line } from "react-icons/ri";


export default function Checkout() {
  const { width } = useScreenSize();

  const { setTrueorFalse, selected, setSelected } = useContext(cartContext);
  const [flagHacerPedido,setflagHacerPedido] = useState(false)
  const { setSelectedSpam,} =
    useContext(cartContext);
  const {userData, cart, cartPlaceOrder} = useSelector(state=>state)
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getDataUser())
    
  }, [])
  const clear = (e) => {
    setSelectedSpam("");
    selected ? setSelected(false) : setSelected(true);
    setTrueorFalse(false);
  };

  const [actualState, changeCheckState] = useState(false);
  const handleChexbox = (e) => {
    changeCheckState(e.target.checked);
  };



  //----------------------------------
const [loading, setLoading] = useState(false);
const history = useHistory()

const  handleLoading =  () => {
  setLoading(true);
  setflagHacerPedido(true)
  dispatch( getPlaceOrder(cart?.code))
   setTimeout(()=>{ 
    setLoading(false);
    let numOrderr = Number(cart.code) + 1
  history.push(`/order-completed/${numOrderr}`)
   }, 5000); 

}

const [flagScroll, setFlagScroll] = useState(true)

  if(flagScroll){
    setFlagScroll(false)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }


  //----------------------------------

  if (width <= 766) {
    return (
      <div className={style.allCheckout}>
        <div className={style.rutas}>
          <Link className={style.inicio} to={"/"} onClick={() => clear()}>
          <RiHome2Line className={style.logoCasa}/> INICIO
          </Link>
          <span>{" " + "/ "}</span>
          <span>
            <Link className={style.inicio} to={"/cart"}>
              CARRO
            </Link>
          </span>
          <span>{" " + "/ "}</span>
          <span className={style.nameRuta}>FINALIZAR PEDIDO</span>
        </div>
        <div className={style.container}>
          <div className={style.containerModo}>
          <div className={style.pago}>
                <div className={style.cont100med}>
                  <span>
                    <PaidIcon style={{ color: "white", fontSize: "50px" }} />
                  </span>
                </div>
                <span className={style.subtitle}>PAGO CON CUENTA</span>
              </div>

              <div className={style.enviarA}>
                <div className={style.cont100med}>
                  <span>
                    <LocalShippingIcon
                      style={{ color: "white", fontSize: "50px" }}
                    />
                  </span>
                </div>
                <span className={style.subtitle4}>DIRECCIÓN DE ENVÍO</span>
                <div className={style.envio}>
                  <span className={style.bolder}>
                  {userData.shippingAddress} , Chile
                  </span>
                </div>
              </div>

            <div className={style.cont100}>
              <span className={style.subBlue}>
                Número De Orden De Compra (Opcional)
              </span>
              <input className={style.input}></input>
            </div>

            <ResumenOrden 
            cartTotalItems={cart?.totalItems}
            cartEntries={cart?.entries}
            />

            <div className={style.cont100}>
              <input
                type="checkbox"
                onChange={handleChexbox}
                checked={actualState}
              ></input>{" "}
              <span className={style.miniTitle}>
                {" "}
                Mediante la colocación de la orden, confirmo que he leído y
                acepto los{" "}
                <a
                  href="https://nuevoportal.agrosuperventas.com/termsAndConditions"
                  target="_blank"
                >
                  Términos y condiciones.
                </a>
              </span>
            </div>
            {loading? (
                  <Spinner></Spinner>
              ):null}
            {actualState ? (
              <button className={style.button} onClick={()=>handleLoading()} disabled={flagHacerPedido}>HACER PEDIDO</button>
            ) : (
              <button disabled="true" className={style.button} >
                HACER PEDIDO
              </button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={style.allCheckout}>
        <div className={style.rutas}>
          <Link className={style.inicio} to={"/"} onClick={() => clear()}>
          <RiHome2Line className={style.logoCasa}/> INICIO 
          </Link>
          <span> / </span>
          <span>
            <Link className={style.inicio} to={"/cart"}>
              CARRO
            </Link>
          </span>
          <span>/</span>
          <span className={style.nameRuta}>FINALIZAR PEDIDO</span>
        </div>
        <div className={style.container}>
          <div className={style.containerModo}>
            <div className={style.cont100}>
              <span className={style.lock}>
                <i class="fa-solid fa-lock"></i>
              </span>{" "}
              <span className={style.title1}>{""}Pago seguro</span>
            </div>

            <div className={style.contPagoEnvio}>
              <div className={style.pago}>
                <div className={style.cont100med}>
                  <span>
                    <PaidIcon style={{ color: "white", fontSize: "50px" }} />
                  </span>
                </div>
                <span className={style.subtitle}>PAGO CON CUENTA</span>
              </div>

              <div className={style.espacioVacio}></div>

              <div className={style.enviarA}>
                <div className={style.cont100med}>
                  <span>
                    <LocalShippingIcon
                      style={{ color: "white", fontSize: "50px" }}
                    />
                  </span>
                </div>
                <span className={style.subtitle4}>DIRECCIÓN DE ENVÍO</span>
                <div className={style.envio}>
                  <span className={style.bolder}>
                  {userData.shippingAddress} , Chile
                  </span>
                </div>
              </div>
            </div>

            <div className={style.cont100}>
              <span className={style.subBlue}>
                Número De Orden De Compra (Opcional)
              </span>
              <input className={style.input}></input>
            </div>

            <div className={style.hr}></div>
            <div className={style.contSpace}>
              <span>Subtotal:</span>
              <span className={style.bolder}>{cart?.subTotal?.formattedValue}</span>
            </div>
            <div className={style.contSpace}>
              <span className={style.green}>Descuentos del pedido:</span>
              <span className={style.bolderGreen}>-{cart?.totalDiscounts?.formattedValue}</span>
            </div>
            <div className={style.contSpace}>
              <span>IVA:</span>
              <span className={style.bolder}>{new Intl.NumberFormat('es-CL', {currency: 'CLP', style: 'currency'}).format(cart?.totalPrice?.value - cart?.subTotal?.value)}</span>
            </div>
            <div className={style.hr}></div>
            <div className={style.cont100}>
              <div className={style.contSpace}>
                <span className={style.bigBolder}>TOTAL DEL PEDIDO</span>
                <span className={style.bigBolder}>{cart?.totalPrice?.formattedValue}</span>
              </div>
            </div>

            <div className={style.cont100}>
              <input
                type="checkbox"
                onChange={handleChexbox}
                checked={actualState}
              ></input>{" "}
              <span className={style.miniTitle}>
                {" "}
                Mediante la colocación de la orden, confirmo que he leído y
                acepto los{" "}
                <a
                  href="https://nuevoportal.agrosuperventas.com/termsAndConditions"
                  target="_blank"
                >
                  Términos y condiciones.
                </a>
              </span>
            </div>
            {loading? (
                  <Spinner></Spinner>
              ):null}
            {actualState ? (
              <button className={style.button} onClick={()=>handleLoading()}>HACER PEDIDO</button>
            ) : (
              <button disabled="true" className={style.button} >
                HACER PEDIDO
              </button>
            )}
          </div>
          <ResumenOrden 
            cartTotalItems={cart?.totalItems}
            cartEntries={cart?.entries}
            />
        </div>
      </div>
    );
  }
}
