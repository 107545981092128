import React, { useContext, useEffect, useState } from "react";
import style from "./Header.module.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import logo from "../../images/agrosuper-blanco.svg";
// import user from "../../images/user.svg";
import cartImg from "../../images/carrito.svg";
import userImg from "../../../src/images/user.svg";
import logoutImg from "../../../src/images/cerrar-sesion.svg";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useDispatch, useSelector } from "react-redux";
import { getBaseStore, getCartProducts, getDataUser, getfrecuencia, getProducts, getSearch, logout } from "../../Services/actions";
import swal from "sweetalert";
import { cartContext } from "../Context/ContextProvider";
import NavBar from "../DrawerMenu/NavBar";
import SearchBar from "../SearchBar/SearchBar";

function Header() {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation()
  const { cartSupport, cart, userData} = useSelector(state => state)
  const despacho = useSelector((state) => state.frecuenciaDespacho);
  const {
    setSelectedSpam,
    setTrueorFalse,
    selected,
    setSelected,
    setfAplicado,
    uncheckAll
  } = useContext(cartContext);


useEffect(() => {
  dispatch(getDataUser())
  dispatch(getCartProducts())
  dispatch(getBaseStore())
}, [cartSupport])

const customerId = localStorage.getItem('B2BCUSTOMER_UID')?.slice(2); // id del cliente sin _c

  const clear = (e) => {
    setSelectedSpam("");
    selected ? setSelected(false) : setSelected(true);
    setTrueorFalse(false);
    setfAplicado({});
    uncheckAll();
    dispatch(getProducts());

  };

  const handleCart = () => {

    if(!cart?.entries?.length){

      swal({
        title: "No hay productos en su carro",
        text: "Agregue productos, para acceder al carrito!",
        icon: "warning",
        button: "Aceptar",
      });
      return
      
    }

    history.push("/cart");
  };
  const onLogout=()=>{
    dispatch(logout())
  }

  return (
    <div>
      <div className={style.ghostHeader}></div>
      <div className={style.direccion}><div><LocationOnOutlinedIcon style={{ color: "#e96d19", fontSize: "20px",  paddingBottom: "3px"}}/></div><div>{userData?.shippingAddress}</div></div>
    <div className={style.headerContainer}>
      <div className={style.menuBurger}>
        <button className={style.btnMenuBurger}>
          <NavBar />
        </button>
      </div>
      <div className={style.containerLogo}>
        <div className={style.logo}>
          <Link to="/" onClick={() => clear()}>
            <img alt="logo" src={logo} />
          </Link>
        </div>
      </div>
      <div className={style.containerBuscador}>
        <SearchBar />
      </div>

      <div className={style.containerButtons}>
        <div className={style.logCart}>
          <ul className={style.loginCartContainer}>
            <li type="none" className={style.logoUser}>
              <button className={style.userBtn} >
                <img alt="user" src={userImg} className={style.imgBtn} />
                <div className={style.text}>
                  <span>¡Hola <span className={style.idStrong}>{userData?.b2bUnitUid}</span>!</span>
                  <span className={style.adressUser}>{userData?.shippingAddress}</span>
                </div>
              </button>
            </li>
            <li type="none">
             {!location.pathname.includes("/order-completed") ? <div className={style.contCartQP}>
              <button onClick={handleCart} className={style.cartBtn}>
                <img alt="carrito" src={cartImg} className={style.imgBtn} />
                <span className={style.totalUnitCart}>{cart?.totalUnitCount || 0}</span>
              </button>
              <span className={style.cartValue}>{cart?.subTotal?.formattedValue || "$0"}</span>
              </div>:<div className={style.contCartQP}>
              <button onClick={handleCart} className={style.cartBtn}>
                <img alt="carrito" src={cartImg} className={style.imgBtn} />
                <span className={style.totalUnitCart}>0</span>
              </button>
              <span className={style.cartValue}>"$0"</span>
              </div>}
            </li>
            <li className={style.containerLogout}>
              <div  onClick={()=>onLogout()} className={style.containerIconLogout}>

              <img alt="logout" src={logoutImg} className={style.iconLogout} />
              </div>
            </li>

          </ul>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Header;
