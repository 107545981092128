import style from "./spinnerImprimir.module.css";



export default function Spinner() {
  return (
    <div className={style.background}>
      <div className={style.ring}>
      </div>
      <div className={style.procesando}>Descargando orden...</div>
    </div>
  );
}
