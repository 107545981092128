import React from 'react'
import { useSelector } from 'react-redux'
import "./Tabs.css"
import style from "./Tabs.module.css"
import parse from 'html-react-parser';
import { DotWave } from '@uiball/loaders';

export default function Tabs() {
   const infoProduct = useSelector(s=>s.infoProductDetail)

    const videosFilter = (obj)=>{

      const arrVideos = Object.entries(obj)

      const videosFilter = arrVideos.filter(e => e[0].includes("youtubeVideoLink"))

      const videos = videosFilter.map(e=> e[1].replace("https://www.youtube.com/watch?v=",""))

      return videos

    }

    const videos = infoProduct && videosFilter(infoProduct)

  return (
    <div className={style.containerTabs}>

<nav>
  <div className={style.navTabs + " nav nav-tabs d-flex justify-content-center gap-2"} id="nav-tab" role="tablist">
    <button className={style.buttonTab + " nav-link active"} id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Detalles del producto</button>
    {videos.length ? (<button className={style.buttonTab + " nav-link"} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Tips y consejos</button>) : (null)}
  </div>
</nav>
<div className="tab-content" id="nav-tabContent">
  <div className="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
    <div className={style.containerDetails}>
      <div className={style.containerUl + " container"}>
        {Object.keys(infoProduct).length && parse(infoProduct.description)}
        </div>
    </div>
  </div>
  <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabIndex="0">

  <div className={style.containerVideosTab}>
    <div className={style.containerVideos + " container"}>
      <div className={style.containerVideoRes}>
        {
            videos.length ?( videos.map((idVideo,i)=>
                <iframe className={style.videoIframe} width="560" height="315" key={i} src={`https://www.youtube.com/embed/${idVideo}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>)
            ):(
              <div className={style.containerLoading}>
              <DotWave size={70} speed={1} color="black" />
            </div>
            )
              }
      </div>
    </div>
  </div>

  </div>
</div>



    </div>
  )
}
