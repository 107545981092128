import React, { useState } from "react";
import { createContext } from "react";
import 'react-toastify/dist/ReactToastify.css';

export const cartContext = createContext();

export default function ContextProvider({ children }) {

  // Use State para el color del menu
  const [selectedSpam, setSelectedSpam] = useState();

  const [trueorfalse, setTrueorFalse] = useState(false);
  const [selected, setSelected] = useState(true);
  const [fAplicado, setfAplicado] = useState({});



  const uncheckAll = () => {
    document.querySelectorAll('input[type=checkbox]').forEach(function(checkElement) {
        checkElement.checked = false;
    });
  } 

  return (
    <cartContext.Provider

      value={{selectedSpam, setSelectedSpam,
        trueorfalse, setTrueorFalse,
        selected, setSelected,
        fAplicado, setfAplicado, uncheckAll}}

    >
      {" "}
      {children}{" "}
    </cartContext.Provider>
  );
}
