import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import style from "./CarrouselUpSellings.module.css";
import { Autoplay, Navigation, Pagination } from "swiper";
import { useSelector } from "react-redux";
import CardBestSellings from "../BestSellings/CardBestSellings/CardBestSellings";
import "../CarrouselCrossSelling/carrouselArrows.css"



export default function CarrouselUpSelling() {
  const { CrossUpSellings,CrossUpSellingsPdp } = useSelector(
    (state) => state
  );

  const products = CrossUpSellings?.upProducts;
  const productsPdp = CrossUpSellingsPdp?.upPdp;


    return (
    <div className={style.conteinerCarrouselUp}>
      <h3>Productos complementarios</h3>
      <Swiper
        modules={[Autoplay, Navigation]}
        navigation={true}
        className={style.swiper + " container"}
        grabCursor={true}
        autoplay={{ delay: 3000 }}
        spaceBetween={1}
        slidesPerView={4}
        breakpoints={{
          1440: {
            slidesPerView: 4,
            spaceBetween: 1,
          },
          1366: {
            slidesPerView: 4,
            spaceBetween: 2,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 2,
          },
          1279: {
            slidesPerView: 3,
            spaceBetween: 2,
          },
          981: {
            slidesPerView: 3,
            spaceBetween: 2,
          },
          980: {
            slidesPerView: 2,
            spaceBetween: 1,
          },

          768: {
            slidesPerView: 2,
            spaceBetween: 2,
          },
          426:{
            slidesPerView: 2,
            spaceBetween: 20,
          },
          350:{
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
        }}
      >
        {products?.length && productsPdp?.length ?(
          products.map((productObj, i) => (
            <SwiperSlide key={i}
            className={style.swiperSlide}>
              <CardBestSellings
                product={productObj}
                productPdp={productsPdp[i]}
              />
            </SwiperSlide>
          ))): (null)}
      </Swiper>
    </div>
  );

}