//eslint-disable-next-line no-restricted-globals
import "./App.css";
import { Route, Switch, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import CardDetail from "./components/CardDetail/CardDetail";
import Footer from "./components/Footer/Footer";
import { useEffect, useState } from "react";
import Cart from "./components/Cart/Cart";
import Menu from "./components/Menu/Menu";
import ContextProvider from "./components/Context/ContextProvider";
import Home from "./components/Home/Home";
import Homepage from "./components/Homepage/Homepage";
import Checkout from "./components/Checkout/Checkout";
import FinalizacionOrden from "./components/FinalizacionOrden/FinalizacionOrden";
import CcommerceProvider from "./components/Context/CcommerceProvider";
import Imprimir from "./components/FinalizacionOrden/Imprimir";
import { Auth } from "./components/Auth/Auth";
function App() {
 
  const data15min = sessionStorage.getItem("DATE15Min");
  const expirationTime15Min = Number(data15min) + 900000; //15min
  const tokenCommerce = sessionStorage.getItem("TOKEN_CCOMMERCE");
  const tokenAuth = sessionStorage.getItem("AUTH_TOKEN");
  const [state, setState] = useState(true);
  const location = useLocation()
  useEffect(() => {
    if (
      data15min &&
      tokenCommerce &&
      Number(new Date().getTime()) >= expirationTime15Min 
    ) {
      sessionStorage.removeItem("TOKEN_CCOMMERCE");
      sessionStorage.removeItem("DATE15Min");
      sessionStorage.removeItem("AUTH_TOKEN");
      setState(false);
      //eslint-disable-next-line no-restricted-globals
      window.location.href = "/c-commerce/signIn/error-en-el-token-de-acceso";
    }
  }, [state,location.pathname]);

  return (
    <div className="App">
      {tokenCommerce && tokenAuth ? (
        <>
          <CcommerceProvider>
            <ContextProvider>
              <Header></Header>
              <Menu></Menu>
              <Switch>
                <Route exact path={"/c-commerce/signIn/:tokenCCommerce"}>
                  <Auth />
                </Route>
                <Route exact path={"/"}>
                  <Homepage />
                </Route>
                <Route exact path={"/home"}>
                  <Home />
                </Route>
                <Route exact path={"/cart"}>
                  <Cart />
                </Route>
                <Route exact path={"/checkout"}>
                  <Checkout />
                </Route>
                <Route exact path={"/order-completed/:id"}>
                  <FinalizacionOrden />
                </Route>
               
                <Route exact path={"/product/:idProduct"}>
                  <CardDetail></CardDetail>
                </Route>
                <Route exact path={"/c/:idCat"}>
                  <Home />
                </Route>
                <Route exact path={"/search"}>
                  <Home />
                </Route>
              </Switch>
              <Footer></Footer>
            </ContextProvider>
          </CcommerceProvider>
        </>
      ) : (
        <>
         <Route exact path={"/print/:id"}>
                  <Imprimir />
          </Route>
          <div className="algosaliomal">¡Ha caducado tu sesión!</div>
          <Route exact path={"/c-commerce/signIn/:tokenCCommerce"}>
            <Auth />
          </Route>
        </>
      )}
    </div>
  );
}

export default App;
