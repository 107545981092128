import axios from "axios";
import { axiosInstanceToken } from "../Interceptor/Interceptor";

const {REACT_APP_USERNAME,REACT_APP_PASSWORD,REACT_APP_CLIENT_ID, REACT_APP_CLIENT_SECRET,REACT_APP_SITE,REACT_APP_GRANTYPE,REACT_APP_API_ADMIN} = process.env

export function getBearerToken(authTokenUser) {

    return async function (dispatch) {
        let success_user= "error";
        try {
            const bearerTokenAdmin = await axios.post(`${REACT_APP_API_ADMIN}/authorizationserver/oauth/token?grant_type=${REACT_APP_GRANTYPE}&username=${REACT_APP_USERNAME}&password=${REACT_APP_PASSWORD}&client_id=${REACT_APP_CLIENT_ID}&client_secret=${REACT_APP_CLIENT_SECRET}&=${REACT_APP_SITE}`);
            const bearerToken = await axiosInstanceToken.get(`/c-commerce/signIn/${authTokenUser}`,{
                headers:{ "Authorization": `Bearer ${bearerTokenAdmin.data.access_token}` }
            });

            localStorage.setItem("TOKEN_CCOMMERCE", authTokenUser)
            localStorage.setItem("AUTH_TOKEN",bearerToken.data.tokenOAuth)
            localStorage.setItem("B2BCUSTOMER_UID",bearerToken.data.b2bCustomerUid)
            localStorage.setItem("DATE15Min", new Date().getTime())
            sessionStorage.setItem("TOKEN_CCOMMERCE", authTokenUser)
            sessionStorage.setItem("AUTH_TOKEN",bearerToken.data.tokenOAuth)
            sessionStorage.setItem("DATE15Min", new Date().getTime())
            success_user = "success"
        } catch (error) {
            console.log(error);
        }
        return dispatch({ type: 'SUCCESS_USER', payload: success_user })

    }
}