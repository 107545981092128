import style from "./Imprimir.module.css";
import { useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import jsPDF from "jspdf";
import SpinnerImprimir from "./spinnerImpresion/spinnerImprimir";
const url = process.env.REACT_APP_API_ADMIN;

export default function Imprimir() {
  const params = useParams().id;
  const reportHtml = useRef(null);
  //const cartPlaceOrder = useSelector(state => state.orderCart)
  let cartPlaceOrder = JSON.parse(localStorage.getItem('ordercart'))
console.log("html",reportHtml.current);
  useEffect(function () {

    function handleGeneratePdf() {
      const doc = new jsPDF('p', 'pt', 'a4');
      
      doc.html(reportHtml.current, {
        html2canvas: { scale: 0.75 },
        async callback(doc) {
          await doc.save(`Pedido${params}-AgrosuperVentas`);
        },
      });
    };

    if (localStorage.getItem("bandera") === "true") {
      (async () => {

        const result = await handleGeneratePdf();

      })()
      setTimeout(() => {
         window.close()
      }, 5000);
      
    }else{
      setTimeout(() => {
      let printContents = document.getElementById("printablediv").innerHTML;
      let originalContents = document.body.innerHTML;
      document.body.innerHTML = printContents;
      document.title = `Pedido${params}-AgrosuperVentas`
      window.print();
      document.body.innerHTML = originalContents;
      window.close();
    }, 500);
    }


    

  });

  const [flagScroll, setFlagScroll] = useState(true)

  if(flagScroll){
    setFlagScroll(false)
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    
    <div className={style.allPrint} >
    <SpinnerImprimir/>  
      <div ref={reportHtml} id="printablediv" className={style.container} >
        <div className={style.container2}>
          <div className={style.cont100}>
            <div className={style.title}>Pedido número : {cartPlaceOrder?.code}</div>
            <div>Fecha de ingreso del pedido : {moment(cartPlaceOrder?.created).utc().format('DD / MM / YYYY HH:mm')}</div>
            <div>Dirección de despacho : {cartPlaceOrder?.deliveryAddress?.formattedAddress}</div>
            {cartPlaceOrder?.diaDespachoFresco ? <div>Fecha de despacho fresco : {cartPlaceOrder?.diaDespachoFresco}</div>: null}
            {cartPlaceOrder?.diaDespachoProcesado ? <div>Fecha de despacho procesado : {cartPlaceOrder?.diaDespachoProcesado}</div>:null}
            <div className={style.hr}></div>
          </div>
          <div className={style.containerResumen}>
            <div className={style.cont100}>
              <span className={style.title}>Resumen del Pedido</span>
            </div>

            <div className={style.cont100}>CANTIDAD DE PRODUCTOS : {cartPlaceOrder?.totalUnitCount
            }</div>
            <div className={style.contTicket}>
              <div className={style.contHola}>
                <div className={style.encTitle}>Descripción del producto</div>
                <div className={style.encInfo}>Precio del ítem</div>
                <div className={style.encInfo}>Cantidad</div>
                <div className={style.encInfo}>Total neto</div>
              </div>
              {cartPlaceOrder?.entries?.map((e, i) => (
                <div className={style.contCard} key={i}>
                  <div className={style.containerImg}>
                    <img
                      className={style.img}
                      src={url + e?.product?.images[2].url}
                      alt={e?.product?.images[2].altText}
                    />
                  </div>
                  <div className={style.cardInfo}>
                    <div className={style.cardTitle}>{e?.product?.name}</div>
                    <div className={style.cardPrecio}>Código : {e?.product?.code}</div>
                  </div>
                  <div className={style.cardPrice}>{e?.basePrice?.formattedValue}</div>
                  <div className={style.cardPrice}>{e?.quantity}</div>
                  <div className={style.cardPrice}>{e?.totalPrice?.formattedValue}</div>
                </div>
              ))}
            </div>
          </div>
          <div className={style.contSpace}>
            <div className={style.title} s>Valor Total Bruto :</div>
            <div>{cartPlaceOrder?.totalPrice?.formattedValue}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
