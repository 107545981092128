import { Link } from "react-router-dom";
import style from "./Checkout.module.css";
const url = process.env.REACT_APP_API_ADMIN;
export default function ResumenOrden({ cartTotalItems, cartEntries }) {
  return (
    <div className={style.containerResumen}>
      <div className={style.cont100pad}>
        <span className={style.title}>Resumen del Pedido</span>
      </div>

      <div className={style.cont100}>
        CANTIDAD DE PRODUCTOS: {cartTotalItems}
      </div>
      {cartEntries?.map((entries, i) => (
        <div key={i} className={style.contCard}>
          <div className={style.containerImg}>
            <img
              className={style.img}
              src={url + entries?.product?.images[2].url}
              alt={entries?.product?.images[2].altText}
            />
          </div>
          <div className={style.cardInfo}>
            <div className={style.cardTitle}>{entries?.product?.name}</div>
            <div className={style.cardPrecio}>
              Precio del item: {entries?.basePrice?.formattedValue}
            </div>
            <div className={style.cardPrecio}>
              {" "}
              <span className={style.cardCant}>CANTIDAD:</span>{" "}
              {entries?.quantity}
            </div>
          </div>
          <div className={style.cardPrice}>
            {entries?.totalPrice?.formattedValue}
          </div>
        </div>
      ))}
    </div>
  );
}
