import axiosInstance from "../Interceptor/Interceptor"

export async function getRequest(source) {
        const result = await axiosInstance.get(source)
        return result

}

export async function promiseAllRequest(sources) {
   
    const dataArr = sources.map((source) => {
        return axiosInstance.get(source);
      })

    const result = await Promise.all(dataArr)
        .then(data=>{
            return data
        }).catch(error=>{
            console.log(error)
        })

    return result

}

export async function postRequest(source,data) {
   
    const result = await axiosInstance.post(source,data)

    return result

}

export async function putRequest(source,data) {
   
    const result = await axiosInstance.put(source,data)

    return result

}

export async function deleteRequest(source,data) {
   
    const result = await axiosInstance.delete(source,data)

    return result

}

