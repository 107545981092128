const initialState = {
  AllProducts: [],
  userData: {},
  productDetailIntegration: {},
  productDetailDiscount: {},
  categories: [],
  subcategories: [],
  paginate: {},
  cart: { entries: [{}] },
  filters: {},
  cartSupport: [],
  busqueda: true,
  catalogo: [],
  productsBestSellings: [],
  productsArrPdp: [],
  userSuccess: "",
  nuestrasMarcas: [],
  CrossUpSellings: {
    crossProducts: [],
    crossPdp: [],
  },
  CrossUpSellingsPdp: {
    upProducts: [],
    upPdp: [],
  },
  productsByfilters: [],
  facets: {
    categoria: {
      name: "",
      topValues: [],
      values: [],
    },
    estado: {
      name: "",
      values: [],
    },
  },
  sort: [],
  currentQuery: {},
  infoProductDetail: {},
  cartPlaceOrder: {},
  categoryProduct:[],
  frecuenciaDespacho: {},
  searchProd: {},
  dropDown: {},
  baseStore:{},
  listaCSV: [],
  minimumCartData:{},
  visibleFlags: {
    banners: false,
    marcas: false
  },
  productRibbon:{},
  propertiesPopUp: []

};
    

export default function rootReducer(state = initialState, action) {
    switch (action.type) {
      case "GET_PRODUCTS":
        return {
          ...state,
          AllProducts: action.payload.productos,
          Paginate: action.payload.paginado,
          filters: action.filters,
          busqueda: action.payload.busqueda,
        };
      case "GET_BASE":
        return {
          ...state,
        };

      case "POST_USER":
        return {
          ...state,
        };
      case "PRODUCT_DETAIL_INTEGRATION":
        return {
          ...state,
          productDetailIntegration: action.payload,
        };
      case "PRODUCT_DETAIL_DISCOUNT":
        return {
          ...state,
          productDetailDiscount: action.payload,
        };
      case "GET_CATEGORIES":
        return {
          ...state,
          categories: action.payload,
        };
      case "SET_SUBCATEGORY":
        return {
          ...state,
          subcategories: action.payload,
        };

      case "CLEAR_SUBCATEGORIES":
        return {
          ...state,
          subcategories: action.payload,
        };

      case "CART_PRODUCTS":
        return {
          ...state,
          cart: action.payload,
          listaCSV: action.payload.entries
        };
      case "SET_QUANTITY":
        return state;

      case "REMOVE_PRODUCT":
        const result = state.cart;
        return {
          ...state,
          cart: result,
          cartSupport: result,
        };
      case "CLEAR_CART":
        return {
          ...state,
          cart: [],
          cartSupport: [],
        };
      case "SET_CART":
        return {
          ...state,
          cart: action.payload,
          cartSupport: action.payload,
        };
      case "PURCHASE_CART":
        return {
          ...state,
          cart: [],
          cartSupport: [],
        };
      case "ADD_PRODUCT":
        return {
          ...state,
          cartSupport: action.payload,
        };

      case "GET_CATALOGO":
        return {
          ...state,
          catalogo: action.payload,
        };
      case "GET_BEST_PRODUCTS":
        return {
          ...state,
          productsBestSellings: action.payload,
        };
      case "GET_PRODUCTS_PDP":
        return {
          ...state,
          productsArrPdp: action.payload,
        };
      case "SUCCESS_USER":
        return {
          ...state,
          userSuccess: action.payload,
        };

      // Casos nuevos

      case "GET_CATALOGO":
        return {
          ...state,
          catalogo: action.payload,
        };
      case "GET_BEST_PRODUCTS":
        return {
          ...state,
          productsBestSellings: action.payload,
        };
      case "GET_PRODUCTS_PDP":
        return {
          ...state,
          productsArrPdp: action.payload,
        };
      case "SET_SUBCATEGORIES":
        return {
          ...state,
          subcategories: action.payload,
        };
      case "NUESTRAS_MARCAS":
        return {
          ...state,
          nuestrasMarcas: action.payload,
        };

      case "GET_CROSS_UP_SELLINGS":
        return {
          ...state,
          CrossUpSellings: {
            ...state.CrossUpSellings,
            crossProducts: action.payload.cross,
            upProducts: action.payload.up,
          },
        };
      case "GET_CROSS_UP_SELLINGS_PDP":
        return {
          ...state,
          CrossUpSellingsPdp: {
            ...state.CrossUpSellingsPdp,
            crossPdp: action.payload.crossPdp,
            upPdp: action.payload.upPdp,
          },
        };
      case "CLEAR_DETAIL":
        return {
          ...state,
          CrossUpSellings: {},
          CrossUpSellingsPdp: {},
          infoProductDetail: {},
          productDetailIntegration: {},
          productDetailDiscount: {},
        };
      case "DATA_USER":
        return {
          ...state,
          userData: action.payload,
        };
      case "FILTER":
        return {
          ...state,
          productsByfilters: action.payload.products,
          paginate: action.payload.pagination,
          currentQuery: action.payload.currentQuery,
          searchProd: action.payload,
          facets: {
            categoria: {
              name: action.payload.facets[0]?.name,
              topValues: action.payload.facets[0]?.topValues
                ? action.payload.facets[0]?.topValues
                : null,
              values: action.payload.facets[0]?.values,
            },
            estado: {
              name: action.payload.facets[1]?.name,
              values: action.payload.facets[1]?.values,
            },
          },
          sort: action.payload.sorts ? action.payload.sorts : null,
        };

      case "CLEAR_DETAIL_CATEGORIES":
        return {
          ...state,
          productsByfilters: [],
          facets: {
            categoria: {
              name: "",
              topValues: [],
              values: [],
            },
            estado: {
              name: "",
              values: [],
            },
          },
        };
      case "INFO_PRODUCT_DETAIL":
        return {
          ...state,
          infoProductDetail: action.payload,
        };

        case "CART_PLACE_ORDER":
          return {
            ...state,
            cartPlaceOrder:action.payload
          };

      case "GET_DESPACHO":
        return {
          ...state,
          frecuenciaDespacho: action.payload,
        };
      case "RESS_PRODUCT":
        return {
          ...state,
          cartSupport: action.payload,
        };
      case "POST_DESPACHO":
        return {
          ...state,
          frecuenciaDespacho: action.payload,
        };
      case "FILTER_BARD":
        return {
          ...state,
          dropDown: action.payload
        }
      case "GET_CATEGORY_PRODUCT":
        return{
          ...state,
          categoryProduct: action.payload
        }
      case "GET_BASE_STORE":
        return {
          ...state,
          baseStore: action.payload
        }
      case "GET_MINIMUM_CART":
        return {
          ...state,
          minimumCartData: action.payload
        }
      case "GET_VISIBLE_FLAGS":
        return {
          ...state,
          visibleFlags: action.payload
        }
      case "GET_CATEGORIES_USER":
        return {
          ...state,
          categories: action.payload,
        }
      case "GET_PRODUCT_RIBBON":
        return {
          ...state,
          productRibbon: action.payload
        };
        case "GET_PROPERTIES_POPUP":
          return {
            ...state,
            propertiesPopUp: action.payload
          };
      default:
        return state;
    }
}
