import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogos, getProductRibbon } from "../../Services/actions";
import CardBestSellings from "./CardBestSellings/CardBestSellings";
import style from "./BestSellings.module.css";
import { DotWave } from "@uiball/loaders";
import { ccommerceProvider } from "../Context/CcommerceProvider";

export default function BestSellings() {
  const { catalogo, productsBestSellings, productsArrPdp,visibleFlags,productRibbon} = useSelector(
    (state) => state
  );

  const {bestProducts} = useContext(ccommerceProvider)

  const dispatch = useDispatch();

  useEffect(() => {
    if (catalogo.length && !productsBestSellings.length) {
      bestProducts();
    } else if (!catalogo.length && !productsBestSellings.length) {
      dispatch(
        getCatalogos()
      );
    }
  }, [catalogo]);

  //paginado----------------------

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 8;

  const totalPages = Math.ceil(productsBestSellings.length / itemsPerPage) 

  const indexLast = currentPage * itemsPerPage;

  const indexFirst = indexLast - itemsPerPage;

  let currentProduct = productsBestSellings.slice(indexFirst, indexLast);
  let currentProductPdp = productsArrPdp.slice(indexFirst, indexLast);

  const nextHandler = (n) => {
    const totalElementos = productsBestSellings.length;

    const nextPage = currentPage + n;

    const index = nextPage * itemsPerPage;

    if (index <= totalElementos + itemsPerPage) {
      // if (index === totalElementos) return;

      setCurrentPage(nextPage);
      
    }
  };

  const prevHandler = (n) => {
    const prevPage = currentPage - n;

    if (prevPage < 1) return;

    setCurrentPage(prevPage);
  };

  return (
    <div className={ visibleFlags?.marcas ? style.containerBestComponent : style.containerBestComponent + " pb-5"} id="bestSeliing">
      <div className={style.containerTitle + " container"}>
        <h3 className={style.title}>Echa un vistazo a nuestros productos más vendidos</h3>
      </div>
      <div className={style.containerDivCards + ' ' + "container"}>
        <div className={style.containerCards}>
          {productsBestSellings.length && productsArrPdp.length
            ? currentProduct.map((productObj, i) => (
              currentProductPdp[i].pdpData.hasERPPrice ?
              <CardBestSellings
                key={productObj.name + i}
                product={productObj}
                productPdp={currentProductPdp[i]}
              />
              :<div></div>
            ))
            : (
              <div className={style.containerNoProducts}>
              <div className={style.containerLoading}>

                <DotWave size={70} speed={1} color="black" />
              </div>
            </div>
            )}
        </div>
      </div>
      <div className={style.containerPaginate}>
        <div className={style.containerBtn}>

        {!((currentPage - 3 ) <= 0) ?(<span className={style.pointsBtn}>{ "..." }</span>) :(null) }
        {!((currentPage - 2) <= 0) ?(<span onClick={()=> prevHandler(2)} className={style.prevNextPage}>{ currentPage - 2 }</span>) :(null) }
        {!((currentPage - 1) <= 0) ?(<span onClick={()=> prevHandler(1)} className={style.prevNextPage}>{ currentPage - 1 }</span>) :(null) }
        <span className={style.currentPage}>{currentPage}</span>
        {((currentPage + 1) <= totalPages) ?(<span onClick={()=> nextHandler(1)}  className={style.prevNextPage}>{ currentPage + 1 }</span>) :(null) }
        {((currentPage + 2) < totalPages + 1) ?(<span onClick={()=> nextHandler(2)}  className={style.prevNextPage}>{ currentPage + 2 }</span>) :(null) }
        {((currentPage + 3) <= totalPages) ?(<span className={style.pointsBtn}>{ "..." }</span>) :(null) }

        </div>
    </div>
    </div>
  );
}
