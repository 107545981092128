import React, { useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { ccommerceProvider } from '../../Context/CcommerceProvider';
import style from "./CardBestSellings.module.css"
import { getProductRibbon } from '../../../Services/actions';
import plusImg from "../../../images/plus-solid.svg";
import minusImg from "../../../images/minus-solid.svg";

export default function CardBestSellings({product, productPdp}) {
// console.log("PRODUCTO PDP", productPdp)
  // agregar al carrito
  const dispatch = useDispatch();
  const {addToCart,flagCartProd,setflagCartProd} = useContext(ccommerceProvider)
  const {cart,productRibbon} = useSelector(s=>s);
  const [quantityCart, setQuantityCart] = useState(1);
  const [html1, setHtml1] = useState("");
  const [url, setUrl] = useState("");
  const handleAddQuantity = (e) => {
    e.preventDefault();
  console.log(quantityCart);  
    // descomentar cuando haya un stock
   // if (quantityCart + 1 > obj.stock) return;

    setQuantityCart(quantityCart + 1);
  };
  const handleRemoveQuantity = (e) => {
    e.preventDefault();

    if (quantityCart - 1 < 1) return;

    setQuantityCart(quantityCart - 1);
  };

  if(cart.code && flagCartProd){
  setflagCartProd(false)
}

  
  const handleAddToCart = ()=>{
    addToCart(product.sku,quantityCart)
    setQuantityCart(1)
  }
useEffect(() => {
  setHtml1(product?.descripcion)
  dispatch(getProductRibbon(product?.sku))
}, [])

const changeQuantity= (e)=>{
  e.preventDefault()
console.log(e.target.value);
setQuantityCart(Number(e.target.value));
}

const getQuantity=()=>{
  if(quantityCart === 0){
    setQuantityCart("")
    return quantityCart
  }
  return quantityCart
}
  return (
    <div className={product?.flagContainer?style.containerCardFilter:style.containerCard}>
       <Link to={`/product/${product?.sku}`}> 
        <div className={style.containerImg}>
        {productRibbon?.image?.url && url.length == 0 && productRibbon?.sku == product?.sku ? setUrl(productRibbon?.image?.url) : ""}
          {url.length > 0 ?<img className={style.cucardaImg} src={url} alt={product?.name} /> : <div></div>}
          <img className={style.img} src={product?.productMedia.url} alt={product?.name} />
        </div>
       </Link> 
      {productPdp?.pdpData.hasERPPrice ? 
            productPdp?.pdpData.descuentoAplicado ? 
              (<div className={style.info}>
                <span className={style.title}>{product?.name}</span>
                <span className={style.unidadTxts2}><span className={style.tachadoUnidad}>{productPdp?.pdpData?.precioNormal}</span> x {productPdp?.pdpData.unidadMedidaVentas}</span>
                <span className={style.unidadTxts}><span className={style.netoPrice}>{productPdp?.pdpData?.precioDescontado}</span> x {productPdp?.pdpData.unidadMedidaVentas}</span>
                <span className={style.productDescription}>{product?.descripcion.length <= 0 ? product?.description.slice(product?.description.lastIndexOf("<br>")+4) : product?.descripcion}</span>
                <span className={style.unidadTxts2}><span className={style.sinDescuentoTachado}>{productPdp?.pdpData?.precioSinDescuento}</span> Neto</span>  
                <span className={style.netoTxt}><span className={style.priceUnidad}>{productPdp?.pdpData?.precioConDescuento}</span> Neto</span>
              </div>)
            :
            (<div className={style.info}>
              <span className={style.title}>{product?.name}</span>
              <span className={style.unidadTxt}><span className={style.netoPrice}>{productPdp?.pdpData?.precioNormal}</span> {productPdp?.pdpData.unidadMedidaVentas ? "x "+productPdp?.pdpData.unidadMedidaVentas : null}</span>
              <span className={style.productDescription}>{product?.descripcion.length <= 0 ? product?.description.split("<br>")[1] : product?.descripcion}</span>
              <span className={style.netoTxt}><span className={style.priceUnidad}>{productPdp?.pdpData?.precioSinDescuento}</span> Neto</span>
        
          </div>)
      
        :
            <div className={style.info}>
                  <span className={style.title}>{product?.name}</span>
                  <span className={style.productDescription}>{product?.descripcion.length <= 0 ? product?.description.split("<br>")[1] : product?.descripcion}</span>
            </div>}

      {productPdp?.pdpData.hasERPPrice ? 
            <div className={style.containerButtons}>
      
            <div className={style.containerAdd}>
              <span onClick={handleRemoveQuantity} className={style.minus}>
              <img alt="plus" src={minusImg} className={style.minus} />
              </span>
              <input className={style.quantity} onChange={e=>changeQuantity(e)} type='number' value={getQuantity()}>
              </input>
              
              <span onClick={handleAddQuantity} className={style.plus}>
                <img alt="plus" src={plusImg} className={style.plus} />
              </span>
            </div>
        
            <button onClick={()=> handleAddToCart()} disabled={flagCartProd} className={style.buttonAdd}>
              Agregar
            </button>
    
        </div>
        :<div className={style.containerButtons}><span className={style.productNotAvailable}>Producto no disponible</span></div>
       }

  </div>
  )
}