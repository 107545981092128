import React, { useState } from "react";
import style from "./CarouselBanners.module.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Pagination } from "swiper";
import { Autoplay } from "swiper";
import { EffectCreative } from "swiper";
import { useSelector } from "react-redux";
import useScreenSize from "../../../Hook/useScreenSize";

export default function CarouselBanners() {
  const { catalogo } = useSelector((s) => s);
  const { width } = useScreenSize();
  const [banners, setBanners] = useState([]);
  const [busqueda, setBusqueda] = useState(true);

  const bestBanners = () => {
    const catalogoFIltered = catalogo?.filter((arr) => !arr.isBestSelling);

    let catalogoSorted = catalogoFIltered.sort(
      (a, b) => b.prioridad - a.prioridad
    );

    catalogoSorted = catalogoSorted[0];

    // eslint-disable-next-line array-callback-return
    const bannersArr = catalogoSorted.banners1.filter((banner) => {
      if (width <= 500 && banner.mediaFormatId === "8796093284403") {
        return banner;
      }

      if (
        width <= 768 &&
        width > 500 &&
        banner.mediaFormatId === "8796093284403"
      ) {
        return banner;
      }

      if (width > 768 && banner.mediaFormatId === "8796093284403") {
        return banner;
      }
    });

    let bannersMap = bannersArr.map((item) => {
      return [item.code, item];
    });

    let bannersMapArr = new Map(bannersMap);

    let unicos = [...bannersMapArr.values()];

    setBanners(unicos);
  };

  if (catalogo.length && busqueda === true) {
    setBusqueda(false);
    bestBanners();
  }

  return (
    <div className="container">
      <Swiper
        modules={[Autoplay, EffectCreative, Pagination]}
        pagination={{
          clickable: true,
        }}
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        effect={"creative"}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: [0, 0, -100],
          },
          next: {
            translate: ["100%", 0, 190],
          },
        }}
        className={style.swiper}
      >
        {banners?.map((banner) => (
          <SwiperSlide className={style.swiperSlide} key={banner.id}>
            <img src={banner.url} alt={banner.code} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
