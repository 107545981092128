import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getProductDiscountVolume } from '../../../Services/actions';
import style from "./TablaDescuento.module.css";
import { DotWave } from '@uiball/loaders';

export default function TablaDescuento() {
    const dispatch = useDispatch()
    const idProduct = useParams().idProduct;
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(getProductDiscountVolume(idProduct))

    }, [dispatch])

    const volumeDis2 = useSelector((state) => state.productDetailDiscount);

    if ( Object.keys(volumeDis2).length && loading === true) {
        setTimeout(() => {
         setLoading(false);
        }, 1000);
        return (
          <div className={style.containerLoading}>
            <DotWave size={70} speed={1} color="black" />
          </div>
        );
      } else{
    return (
        <div className={style.tablaVolumenDescContainer}>
            {Object.keys(volumeDis2).length !==0 ?<>
            <h3 className={style.tablaVolumenDescH2}>Producto con descuento por volumen</h3>

            <table className={style.tablaVolumenDesc}>

                <tr className={volumeDis2?.volumeDiscounts?.length %2 ===0?style.tablaVolTr:style.tablaVolTr1}>

                    <td><strong>Cantidad de productos</strong></td>

                    <td><strong>Precio Unitario</strong></td>

                    <td><strong>Porcentaje de Desct.</strong></td>

                </tr>



                {volumeDis2?.volumeDiscounts?.map((e,i) =>

                    <tr key={i} className={volumeDis2?.volumeDiscounts?.length %2 ===0?

                    i%2!==0 ? style.tablaVolTr:style.tablaVolTr2:i%2===0 ? style.tablaVolTr:style.tablaVolTr2}>

                        <td>{e.minQuantity}{e.maxQuantity ? "-" + e.maxQuantity : " +"}</td>

                        <td>{e.formattedValue}</td>

                        <td>{e.formattedPercentage}</td>

                    </tr>

                )}



            </table></> : <></>}
            
        </div>
    )
    }
}