import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import style from "./NavBar.module.css";
import { FaBars } from "react-icons/fa";
import userImg from "../../../src/images/user.svg";
import logoutImg from "../../../src/images/cerrar-sesion.svg";
import closeImg from "../../../src/images/close-solid.svg";
import { clearDetailCategories, logout, setSubcategories } from "../../Services/actions";
import { Link } from "react-router-dom";
import logo from "../../images/agrosuper-blanco.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ccommerceProvider } from "../Context/CcommerceProvider";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import("./nav.css");

export default function NavBar() {
  const categoria = useSelector((state) => state.categories);
  const {  selectedOrder, setSelectedOrder } = useContext(ccommerceProvider);
  const dispatch = useDispatch();
  const history = useHistory()

  const { subcategories,userData } = useSelector((s) => s);
  const [show, setShow] = useState(false);

  const [showSub, setShowSub] = useState("");


  const [find, setFind] = useState([]);

  const subCate = (texto) => {
    setShowSub(texto);

    const found = categoria.categories.find((e) => {
      return texto === e.title;
    });

    setFind([found.subcategories]);

    dispatch(setSubcategories(found.subcategories));
  };
  const onclick=(id)=>{
    setShow(false)
    dispatch(clearDetailCategories());
    localStorage.removeItem("query")
    localStorage.removeItem("order")
    setSelectedOrder(!selectedOrder) 
    };

  const onLogout=()=>{
    dispatch(logout())
    setShow(!show)

  }


  return (
    <div className={style.container}>
      <div className={style.wrapper}>
        <div
          onClick={() => {
            setShow(!show);
            setShowSub("");
          }}
          className={style.mobileIcon}
        >
          <FaBars />
        </div>

        <div className={show ? style.menu : style.menuClose}>
          <div className={style.containerLogoClose}>
            <div className={style.containerLogo}>
              <img className={style.img} src={logo} alt="Logo" />
            </div>
            <div className={style.close}>
            <img className={style.img} onClick={() => setShow(!show)} src={closeImg} alt="Logo" />

            </div>
          </div>
          <div className={style.containerItems}>
            {categoria.categories &&
              categoria.categories.map((e, i) => (
                <div key={i + e.id} className={style.containerItemsIcons}>
                  <Link  onClick={() => onclick()} to={`${e.link}`} className={style.categories}>
                    <div className={style.categorySE}>
                      <span className={style.items}>{e.title}</span>
                    </div>
                  </Link>
                  <div
                    onClick={() => subCate(e.title)}
                    className={style.iconDrop}
                  >
                    <ChevronRightIcon
                      style={{
                        color: "#0068b3",
                        fontWeight: "600",
                        fontSize: "20px",
                      }}
                    />
                    {/* <i className="fa-solid fa-greater-than"></i> */}
                  </div>
                </div>
              ))}
          </div>

          <div className={showSub.length ? "navShow" : "navNoShow"}>
            <div onClick={() => setShowSub("")} className={style.dropDownBack}>
              <ChevronLeftIcon    style={{
                              color: "#0068b3",
                              fontWeight: "600",
                              fontSize: "20px",
                            }}/>
              <span className={style.spanSub}>{showSub}</span>
            </div>
            <div className={style.containerSubCategories}>
              <div className={style.subCategory}>
                {subcategories.length &&
                  subcategories.map((e, i) => {
                    return (
                      <Link to={`${e.link}`} className={style.categories}>
                        <div onClick={() => onclick()} className={style.containerItemsIcons2}>
                        <span key={i}>{e.title}</span>
                        <div>
                          <ChevronRightIcon
                            style={{
                              color: "#0068b3",
                              fontWeight: "600",
                              fontSize: "20px",
                            }}
                          />
                        </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>

          <div className={style.ghost}></div>
          <div className={style.faqs}>
            <div className={style.containerFaqs}>
                <img alt="user" src={userImg} className={style.imgBtn} />
                <div className={style.containerUserData}>
                  <span className={style.spanText}>¡Hola <span className={style.idStrong}>{userData?.b2bUnitUid}</span>!</span>
                  
                </div>
            </div>
            <div onClick={()=> onLogout()} className={style.containerLogout}>
            <img alt="logout" src={logoutImg} className={style.iconLogout} />
            <span className={style.spanText}>Cerrar Sesión</span>
            </div>

          </div>
        </div>
        <div className={show ? style.back : style.backClose}></div>
      </div>
    </div>
  );
}
